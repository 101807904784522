import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import SelectGroup from "./SelectGroup.js";
import { components } from "react-select";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import * as types from "../../constants/actionTypes";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const useStyles = makeStyles(() => ({
  optionsCheckbox: {
    margin: "-5px 5px 0 0",
    position: 'relative',
    verticalAlign: 'middle',
  },
}));


const Option = props => {
  const classes = useStyles();
    return (
      <div>
        <components.Option {...props}>
          {/* {props.isSelected || props.data['selected'] === true ?  <CheckBoxIcon className={classes.optionsCheckbox} color="primary"></CheckBoxIcon> : <CheckBoxOutlineBlankIcon className={classes.optionsCheckbox} color="primary"></CheckBoxOutlineBlankIcon>} */}
          <div>{props.label}</div>
        </components.Option>
      </div>
    );
};

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const DropdownIndicator = props => {
  const { selectProps } = props;
  let arrow;
  if (selectProps.menuIsOpen) {
   arrow = <ArrowDropUpIcon></ArrowDropUpIcon>;
  } else {
    arrow = <ArrowDropDownIcon></ArrowDropDownIcon>;
  }
  return <components.DropdownIndicator {...props}>{arrow}</components.DropdownIndicator>;
 }

export default function CountrySelect({ data, onChange, value }) {
  const dispatch = useDispatch();
  const { continentsList } = useSelector((state) => state.report);
  const setSelectedContinents = (val) => dispatch({ type: types.SET_CONTINENTS_LIST, value: val });

  const handleChange = selected => {
    let countryCodes = []
    let continentCodes = []
    selected.map((record)=>{ 
      if (Number.isInteger(record.value)) { 
        countryCodes = countryCodes.concat(record)
      } else {
        continentCodes = continentCodes.concat(record)
      }
    })
    setSelectedContinents(continentCodes)
    onChange(countryCodes)
  };

  let groups = {}
  let groupsList = []
  let options = []
  data.map((record)=>{
    if (groups[record.continent] === undefined){
      groupsList=groupsList.concat(record.continent)
      groups[record.continent]=[]
    }
    groups[record.continent]=groups[record.continent].concat(record)
  })

  Object.keys(groups).map((key,value)=>{
    options = options.concat({"value":key, "label":key, "continent":key, "isShow":true, "selected":false, "groupName":true})
    groups[key].map((record)=>{
      options = options.concat(record)
    })
  })

  return (
    <SelectGroup
      groups={groups}
      groupsList={groupsList}
      options={options}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      maximumSelectionLength={5}
      components={{ Option, MultiValue, DropdownIndicator }}
      onChange={handleChange}
      placeholder={<div>All Countries</div>}
      allowSelectAll={true}
      value={[...continentsList, ...value]}
    />
  );
}