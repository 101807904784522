import React from "react";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import AdvancedTable from "../../components/AdvancedTable";
import {
  apiGetDailyDepositData,
  apiDailyDepositFileDownload
} from "../../resource/index.js";
import Storage from "../../common/lib/storage";
import useAPIError from "../../common/hooks/useAPIError";
import useSearchSYN from "../../common/hooks/useSearchSYN"
import theme from '../../constants/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: (dynamicWidth) => dynamicWidth - theme.spacing(39).replace('px', ''),
  },
  topDiv: {
    display: 'flex',
    height: "500px",
    position: 'relative',
    width: (dynamicWidth) => dynamicWidth - theme.spacing(40).replace('px', ''),
  },
}));

const DepositDailyLink = ({ value, onClick}) => {
  return (
    <div style={{ textAlign: "left" }}>
      {( value && value !== "-" ? (
        <Link component="button" variant="body2" onClick={onClick}>
          {value}
        </Link>
      ) : (
        value
      ))}
    </div>
  );
};

export default function DepositDailyReport() {
  const { addError } = useAPIError();
  // start table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [noData, setNoData] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [selectedSort, setSelectedSort] = React.useState({});
  const regulator = Storage.getRegulator() ?? "";
  const { toggleSearchSYN } = useSearchSYN();

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Date",
          accessor: "date_str",
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "File Name",
          accessor: "file_name",
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <DepositDailyLink
              value={cell.value}
              onClick={() => {
                downloadFile(cell.value)
              }}
            />
          ),
        },
        {
          Header: "Update Time",
          accessor: "update_time",
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        }
      ], [regulator]);

  const downloadFile = (file) => {
    setLoading(true);
    apiDailyDepositFileDownload(file)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        addError("Failed to download file");
      })
  };

  const fetchData = ({ pageSize, pageIndex, sortParams, filterParams }) => {
    const dailyReportPermitted = Storage.getDailyReportRole();
    if (dailyReportPermitted) {
      setSelectedSort(sortParams);
      setLoading(true);
      setNoData(false);
      apiGetDailyDepositData({
        regulator: regulator,  // Have to put a valid regulator
        pageNo: pageIndex + 1,
        pageSize: pageSize,
        sort: selectedSort,
      })
        .then((res) => {
          if (res.data.status === "success") {
            setData(res.data.data.fileList);
            setCount(res.data.data.total);
            setPageCount(Math.ceil(res.data.data.total / pageSize));
          }else{
            setNoData(true);
            setData([]);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setNoData(true);
          setData([]);
        })
    } else {
      setLoading(false);
      setNoData(true);
      setData([]);
    }
  }

  const [dynamicWidth, setDynamicWidth] = React.useState(window.innerWidth);
  const classes = useStyles(dynamicWidth);

  function handleResize() {
    setDynamicWidth(window.innerWidth)
  }
  window.addEventListener('resize', handleResize)

  return (
    <div className={classes.root}>
      <AdvancedTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        noData={noData}
        count={count}
        pageCount={pageCount}
      />
    </div>
  );
}
