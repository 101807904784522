import { withStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import Button from "@mui/material/Button";
import theme from '../../constants/theme';

const PrimaryButton = withStyles(() => ({
  root: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: "#1976d2",
    borderRadius: "20px",
    "&:hover": {
      border: `1px solid  ${indigo[300]}`,
      backgroundColor: indigo[300],
    },
  },
}))(Button);

export default PrimaryButton;
