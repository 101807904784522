class ErrorHandler {
/**
 * Returns a converted error message to display
 *
 * @param {msg} object error message to be converted
 */
    static convertErrMsg = (msg) => {
        let msgString = '';
        if (typeof (msg) == "object") {
            for (const [key, value] of Object.entries(msg)) {
                msgString += `${key}: ${value}\n`
            }
        } else {
            msgString = msg;
        }

        return msgString ?? "Invalid Input";
    }
}
export default ErrorHandler;