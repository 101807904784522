import React from 'react';
import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Clear from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import RangeInputField from './RangeInputField';
import theme from '../../constants/theme';

const useStyles = makeStyles(() => ({
    root: {
        width: theme.spacing(55),
        padding: 5,
        display: 'flex'
    },
    operatorField: {
      "& .MuiFilledInput-root": {
          background: "white"
      },
      width: 120
    },
    inputField: {
        "& .MuiFilledInput-root": {
            background: "white"
        },
        width: '100%'
    },
    operator: {
        // width: theme.spacing(20),
        flexGrow: 1,
        margin: 5
    },
    value: {
        flexGrow: 2,
        margin: 5
    },
    typography: {
      padding: theme.spacing(2),
    },
    activeIcon: {
      color: theme.palette.primary.main
    },
    icon: {
      color: theme.palette.text.primary
    }
  }));

const operators = [
    {
      value: 'equals',
      label: 'equals',
      type: ['string', 'number']
    },
    {
      value: 'contains',
      label: 'contains',
      type: ['string']
    },
    {
      value: 'not',
      label: 'does not contain',
      type: ['string']
    },
    {
      value: 'between',
      label: 'between',
      type: ['number']
    },
    {
      value: 'isin',
      label: 'is in',
      type: ['string', 'number']
    }
  ];

export default function FilterBox({
    type,
    value,
    onChange,
    anchorEl,
    handleClose
}) {
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const [operator, setOperator] = React.useState('equals');
    const [keyvalue, setKeyvalue] = React.useState(value || '');

    const handleSelect = (v) => {
        setKeyvalue('');
        setOperator(v);
    };

    const handleChange = (v) => {
        setKeyvalue(v);
    };

    const clear = () => {
        setKeyvalue('');
    }

    React.useEffect(() => {
      if (!keyvalue) {
        onChange('');
      } else if (operator === 'isin') {
        onChange({[operator]: keyvalue.split(/\n/).filter(r => r)});
      } else {
        onChange({[operator]: keyvalue});
      }
    }, [operator, keyvalue])

    const getInput = () => {
      switch(operator) {
        case 'between':
          return (
            <RangeInputField
              value={keyvalue}
              onChange={handleChange}
            />)
        case 'isin':
          return (
            <TextField
                id="filter-box-value"
                size="small"
                variant="outlined"
                label="one value per line"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                value={keyvalue}
                onChange={e => handleChange(e.target.value)}
                className={classes.inputField}
                InputProps={{
                  endAdornment: (<IconButton onClick={clear}>
                      <Clear className={classes.icon} />
                    </IconButton>)
                }}
            />)
        default:
          return (
            <TextField
                id="filter-box-value"
                size="small"
                variant="filled"
                label="value"
                InputLabelProps={{
                  shrink: true,
                }}
                value={keyvalue}
                onChange={e => handleChange(e.target.value)}
                className={classes.inputField}
                InputProps={{
                  endAdornment: (<IconButton onClick={clear}>
                      <Clear className={classes.icon} />
                    </IconButton>)
                }}
            />)
      }
    }
    return (
        <div>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.root}>
                <div className={classes.operator}>
                    <TextField
                        id="filter-box-operator"
                        size="small"
                        variant="filled"
                        select
                        value={operator}
                        onChange={e => handleSelect(e.target.value)}
                        className={classes.operatorField}
                        >
                        {operators.filter(r => r.type.includes(type)).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className={classes.value}>
                  {getInput()
                  }
                </div>
            </div>
          </Popover>
        </div>
      );
}