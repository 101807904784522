import { ROUTES } from "../constants/constants";
import RebateReport from "../pages/RebateReport/RebateReport";
import RebateFilter from '../pages/RebateReport/RebateFilter';
import DepositReport from "../pages/DepositReport/DepositReport";
import DepositFilter from "../pages/DepositReport/DepositFilter";
import CampaignReport from "../pages/CampaignReport/CampaignReport";
import ChannelData from "../pages/ChannelData/ChannelData";
import FTDeposit from "../pages/FTDeposit/FTDepositReport";
import FTDepositFilter from "../pages/FTDeposit/DepositFilter";
import TradeReport from "../pages/TradeReport/TradeReport";
import TradeReportV2 from "../pages/TradeReport/TradeReportV2";
import TradeFilter from "../pages/TradeReport/TradeFilter";
import DepositDailyReport from "../pages/DepositDailyReport/DepositDailyReport";
import CCTransactionReport from "../pages/CCTransactionReport/CCTransactionReport";
import CCTransactionFilter from "../pages/CCTransactionReport/CCTransactionFilter";
import CCWithdrawDetailReport from "../pages/CCWithdrawDetailReport/CCWithdrawDetailReport";
import CCWithdrawDetailFilter from "../pages/CCWithdrawDetailReport/CCWithdrawDetailFilter";

const Routes = [
  {
    path: "/",
    component: RebateReport,
    filter: RebateFilter
  },
  {
    path: ROUTES.REBATE_REPORT,
    component: RebateReport,
    filter: RebateFilter
  },
  {
    path: ROUTES.DEPOSIT_REPORT,
    component: DepositReport,
    filter: DepositFilter
  },
  {
    path: ROUTES.TRADE_REPORT,
    component: TradeReport,
    filter: TradeFilter
  },
  {
    path: ROUTES.TRADE_REPORT_V2,
    component: TradeReportV2,
    filter: TradeFilter
  },
  {
    path: ROUTES.FTD_DEPOSIT,
    component: FTDeposit,
    filter: FTDepositFilter
  },
  {
    path: ROUTES.CAMPAIGN_REPORT,
    component: CampaignReport,
  },
  {
    path: ROUTES.DEPOSIT_DAILY_REPORT,
    component: DepositDailyReport,
  },
  {
    path: ROUTES.CREDIT_CARD_TRANSACTION_REPORT,
    component: CCTransactionReport,
    filter: CCTransactionFilter
  },
  {
    path: ROUTES.CREDIT_CARD_WITHDRAWAL_DETAIL_REPORT,
    component: CCWithdrawDetailReport,
    filter: CCWithdrawDetailFilter
  },
  {
    path: ROUTES.CHANNEL_DATA,
    PSPRoleRequired: true,
    component: ChannelData,
  }
];

export default Routes;
