import { withStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import theme from '../../constants/theme';

const ToolBarButton = withStyles(() => ({
  root: {
    color: theme.palette.getContrastText(grey[500]),
    fontWeight: 600,
    border: `1px solid  ${grey[300]}`,
    borderRadius: "20px",
    "&:hover": {
      border: `1px solid  ${grey[300]}`,
      backgroundColor: grey[300],
    },
  },
}))(Button);

export default ToolBarButton;
