import React, { useEffect, useState } from "react";
import SelectFilter from '../../components/searchBar/SelectFilter';
import MenuItem from "@mui/material/MenuItem";
import Storage from "../../common/lib/storage";

const CCDropDownMenu = ({
  label,
  value,
  defaultValue,
  apiFunction,
  dataKey,
  onChange,
  inputMenuList = null
}) => {
  const [ccMenuList, setCCMenuList] = useState([]);
  const regulator = Storage.getRegulator() ?? "";

  useEffect(() => {
    if (Array.isArray(inputMenuList) && inputMenuList.length > 0) {
      setCCMenuList(inputMenuList);
    } else if (apiFunction !== null){
      apiFunction(regulator)
      .then((res) => {
        if (res.data.status === "success") {
          setCCMenuList(res.data.data[dataKey]);
        }
      })
      .catch((e) => {
        setCCMenuList([]);
      })
    } else {
      setCCMenuList([]);
    }
  }, [regulator, inputMenuList]);

  return (
      <SelectFilter
          size="small"
          select
          variant="outlined"
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            value: value || "",
            onChange: (e) => onChange(e.target.value || ""),
            displayEmpty: true,
          }}
      >
          <MenuItem value=""><em>{defaultValue}</em></MenuItem>
          {Array.isArray(ccMenuList) && ccMenuList.length > 0 ? (ccMenuList.map(r => <MenuItem key={r.label} value={r.label}>{r.value}</MenuItem>)) : null}
      </SelectFilter>
  )
}

export default CCDropDownMenu;