import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import AdvancedTable from "../../components/AdvancedTable";
import {
  StringComboFilter,
  NumberComboFilter,
  SelectMultipleColumnsFilter,
} from "../../components/filterTypes/filterTypes";
import {
  apiGetCountry,
  apiGetMt4Account,
  apiGetDataSource,
  apiGetTradeAction,
  apiDownloadFile,
  apiGetTradeDownloadData,
  apiGetTradeData,
} from "../../resource/index.js";
import Storage from "../../common/lib/storage";
import DateRange from "../../common/lib/dateRange";
import * as types from '../../constants/actionTypes';
import { 
  ACCOUNT_TYPES,
  DEFAULT_TRADE_STATE,
} from '../../constants/constants';
import useAPIError from "../../common/hooks/useAPIError";
import {NumberFormatter} from "../../util/tableHelper";
import theme from '../../constants/theme';
import TradeSummary from './TradeSummary'
import { XSSRecover } from "../../util/xssHelper";

const useStyles = makeStyles(() => ({
  root: {
    width: (dynamicWidth) => dynamicWidth - theme.spacing(39).replace('px', ''),
  },
  topDiv: {
    display: 'flex', 
    height: "500px",
    position: 'relative',
    width: (dynamicWidth) => dynamicWidth - theme.spacing(40).replace('px', ''),
  },
}));

export default function TradeReport() {
  const { seletedMt4AccountList, seletedMt4ServerList, seletedActionList } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setTradeState = (val) => dispatch({ type: types.SET_TRADE_STATE, value: val });
  const setMt4Account = (val) => dispatch({ type: types.SET_MT4_ACCOUNT_LIST, value: val});
  const setSelectedMt4Accounts = (val) => dispatch({ type: types.SET_SELECTED_MT4_ACCOUNT_LIST, value: val });
  const setMt4Server = (val) => dispatch({ type: types.SET_MT4_SERVER_LIST, value: val});
  const setSelectedMt4Servers = (val) => dispatch({ type: types.SET_SELECTED_MT4_SERVER_LIST, value: val });
  const setAction = (val) => dispatch({ type: types.SET_ACTION_LIST, value: val});
  const setSelectedActions = (val) => dispatch({ type: types.SET_SELECTED_ACTION_LIST, value: val });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });
  const setCountry = (val) => dispatch({ type: types.SET_COUNTRY_LIST, value: val});
  const setSelectedCountry = (val) => dispatch({ type: types.SET_SELECTED_COUNTRIES, value: val});
  const setSelectedContinents = (initState) => dispatch({ type: types.SET_CONTINENTS_LIST, value: initState });  
  const { addError } = useAPIError();
  // start table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [noData, setNoData] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [selectedSort, setSelectedSort] = React.useState({});
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [summary, setSummary] = React.useState([]);
  const regulator = Storage.getRegulator() ?? "";
  const { filters } = useSelector(state => state.report);
  const isTradingAccount = filters.accountType === ACCOUNT_TYPES.TRADING_ACCOUNT;
  const [dynamicColumns, setDynamicColumns] = React.useState([]);

  React.useEffect(() => {
    const displayClickId = (Storage.getDisplayClickID() === '1');
    if (displayClickId === true) {
      setDynamicColumns([{
        Header: "Click ID",
        accessor: "click_id",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, cell }) => (
          <div style={{ textAlign: "left" }}>
            {displayClickId ? cell.value : '-'}
          </div>
        )}]);
    } else {
      setDynamicColumns([]);
    }
  }, []);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Agent",
          accessor: "ibname",
          disableSortBy: false,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Customer Name",
          accessor: "clientName",
          disableSortBy: true,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Account",
          accessor: "mt4Account",
          disableSortBy: true,
          disableFilters: false,
          Filter: NumberComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Ticket ID",
          accessor: "ticket",
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Type",
          accessor: "cmd",
          id: 'cmd',
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Symbol",
          accessor: "symbol",
          id: 'symbol',
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Volume",
          accessor: "volume",
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{NumberFormatter(cell.value,2,5)}</div>
          ),
        },
        {
          Header: "Open Price",
          accessor: "open_price",
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{NumberFormatter(cell.value,2,5)}</div>
          ),
        },
        {
          Header: "Open Time",
          accessor: "open_time",
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Close Price",
          accessor: "close_price",
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{NumberFormatter(cell.value,2,5)}</div>
          ),
        },        
        {
          Header: "Close Time",
          accessor: "close_time",
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Stop Loss",
          accessor: "stop_loss",
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{NumberFormatter(cell.value,2,5)}</div>
          ),
        },
        {
          Header: "Take Profit",
          accessor: "take_profit",
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{NumberFormatter(cell.value,2,5)}</div>
          ),
        },
        {
          Header: "Commission",
          accessor: "commission",
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{NumberFormatter(cell.value,2,5)}</div>
          ),
        },
        {
          Header: "Interest",
          accessor: "interest",
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{NumberFormatter(cell.value,2,5)}</div>
          ),
        },
        {
          Header: "Profit/Loss",
          accessor: "profit",
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{NumberFormatter(cell.value,2,5)}</div>
          ),
        },
        {
          Header: "Trade on Credit",
          accessor: "trade_on_credit",
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Server",
          accessor: "server",
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Country",
          accessor: "country",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        ...dynamicColumns,
      ], [isTradingAccount, dynamicColumns]);

  const downloadFile = (file) => {
    apiDownloadFile(file)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([
            new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
            "",
            res.data],
            { type: "text/plain;charset=utf-8" }
            ));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
  };

  const getDownloadData = () => {
    setLoading(true);
    apiGetTradeDownloadData({
      regulator: regulator,
      sort: selectedSort,
      filter: selectedFilter ? selectedFilter : {},
      from: filters.fromDate || "",
      to: filters.toDate || "",
      accountType: filters.accountType || undefined,
      countryCodes: filters.countryCodes || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
      server: seletedMt4ServerList ? seletedMt4ServerList.map(r => r.value) : undefined || undefined,
      action: seletedActionList ? seletedActionList.map(r => r.value) : undefined || undefined,    
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          downloadFile(res.data.data.file);
        }
      })
      .catch(() => {
        setLoading(false);
	  })
  };

  const getData = React.useCallback(async () => {
    try {
      const country_res = (await apiGetCountry(regulator)).data;
      setCountry(country_res.data.country);

      const mt4_account_res = (await apiGetMt4Account(regulator)).data.data.accountTypes;
      setMt4Account(Object.keys(mt4_account_res).map(key => { return {"label": key, "value": mt4_account_res[key]}}).filter((record) => record.value > 0 ));
    } catch (err) {
      console.log(err);
      setCountry([]);
      setMt4Account([]);
    }
  }, [regulator]);

  const getServerData = React.useCallback(async () => {
    try {
      const mt4_server_res = (await apiGetDataSource(regulator)).data.data.dataSource; 
      setMt4Server(mt4_server_res);
    } catch (err) {
      console.log(err);
      setMt4Server([]);
    }
  }, [regulator]);
  
  const getActionData = React.useCallback(async () => {
    try {
      const mt4_action_res = (await apiGetTradeAction()).data.data.actions; 
      setAction(Object.keys(mt4_action_res).map(key => { return {"label": key, "value": mt4_action_res[key]}}));
    } catch (err) {
      console.log(err);
      setAction([]);
    }
  }, [regulator]);

  React.useEffect(() => {
    if (regulator) {
        getData();
        getServerData();
        getActionData();
    }
  }, [regulator])
  
  const fetchData = ({ pageSize, pageIndex, sortParams, filterParams }) => {
    if (DateRange.FindRangeError(filters.fromDate, filters.toDate)) {
      addError(DateRange.FindRangeError(filters.fromDate, filters.toDate));
      return;
    }
    const filterOptions = {
      accounttype: seletedMt4AccountList ? seletedMt4AccountList.map(r => r.value) : undefined || undefined,
      agent: filterParams.ibname || undefined,
      clientName: filterParams.clientName || undefined,
      account: filterParams.mt4Account && filterParams.mt4Account["="] ? filterParams.mt4Account["="] : (filterParams.mt4Account || undefined),
    }
    setSelectedSort(sortParams);
    setSelectedFilter(filterOptions);
    setLoading(true);
    setNoData(false);
    apiGetTradeData({
      regulator: regulator,
      pageNo: pageIndex + 1,
      pageSize: pageSize,
      sort: sortParams,
      filter: filterOptions ? filterOptions : {},
      from: filters.fromDate,
      to: filters.toDate,
      accountType: filters.accountType || undefined,
      countryCodes: filters.countryCodes || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
      server: seletedMt4ServerList ? seletedMt4ServerList.map(r => r.value) : undefined || undefined,
      action: seletedActionList ? seletedActionList.map(r => r.value) : undefined || undefined,      
    })
      .then((res) => {
        if (res.data.status === "success") {
          setData(res.data.data.trade);
          setCount(res.data.data.total);
          setPageCount(Math.ceil(res.data.data.total / pageSize));
          setSummary(res.data.data.summary);
        }else{
          setNoData(true);
          setData([]);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setNoData(true);
        setData([]);
        setSummary([]);
      })

  }
  
  const [dynamicWidth, setDynamicWidth] = React.useState(window.innerWidth);
  const classes = useStyles(dynamicWidth);

  function handleResize() {
    setDynamicWidth(window.innerWidth)
  }
  window.addEventListener('resize', handleResize)

  const resetFilter = () => {
    setSelectedCountry([]);
    setSelectedContinents([]);
    setSelectedMt4Accounts([]);
    setSelectedMt4Servers([]);
    setSelectedActions([]);
    setUserAndLevelName("");
    setLevelId("");
    setShowAll(true);        
    setTradeState(DEFAULT_TRADE_STATE);
  }

  return (
    <div className={classes.root}>
      <AdvancedTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        noData={noData}
        count={count}
        pageCount={pageCount}
        getDownloadData={getDownloadData}
        resetFilter={resetFilter}
        getCustomFooter={() => (
          <TradeSummary
            data = {summary}
          />
        )}
      />
    </div>
  );
}
