import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AdvancedTable from "../../components/AdvancedTable";
import {
  StringComboFilter,
  NumberComboFilter,
  SelectMultipleColumnsFilter,
} from "../../components/filterTypes/filterTypes";
import {
  apiGetCurrency,
  apiGetCountry,
  apiGetMt4Account,
  apiDownloadFile,
  apiGetDepositDownloadData,
  apiGetDepositData,
  apiGetDepositSummary,
  apiGetDepositDifferenceData,
  apiGetDepositDifferenceDownloadData,
  apiGetDepositDifferenceSummary,
  apiGetCurrencyPrice
} from "../../resource/index.js";
import Storage from "../../common/lib/storage";
import DateRange from "../../common/lib/dateRange";
import * as types from '../../constants/actionTypes';
import {
  FUND_TYPES,
  ACCOUNT_TYPES,
  DEFAULT_DEPOSITATE_STATE,
} from '../../constants/constants';
import ControlledMap from "../../components/geo/ControlledMap";
import DepositSummary from "./DepositSummary";
import useAPIError from "../../common/hooks/useAPIError";
import useSearchSYN from "../../common/hooks/useSearchSYN"
import {NumberFormatter} from "../../util/tableHelper";
import theme from '../../constants/theme';
import { XSSRecover } from "../../util/xssHelper";

const useStyles = makeStyles(() => ({
  root: {
    width: (dynamicWidth) => dynamicWidth - theme.spacing(39).replace('px', ''),
  },
  topDiv: {
    display: 'flex',
    height: "500px",
    position: 'relative',
    width: (dynamicWidth) => dynamicWidth - theme.spacing(40).replace('px', ''),
  },
}));

const find = (arr, key, value) => {
  for (let item of arr) {
    if (item[key] === value) {
      return item;
    }
  }

  return null;
}

export default function DepositReport() {
  const { seletedMt4AccountList, deposityState } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setCountry = (val) => dispatch({ type: types.SET_COUNTRY_LIST, value: val});
  const setMt4Account = (val) => dispatch({ type: types.SET_MT4_ACCOUNT_LIST, value: val});
  const setSelectedCountry = (val) => dispatch({ type: types.SET_SELECTED_COUNTRIES, value: val});
  const setSelectedMt4Accounts = (val) => dispatch({ type: types.SET_SELECTED_MT4_ACCOUNT_LIST, value: val });
  const setDeposityState = (val) => dispatch({ type: types.SET_DEPOSITY_STATE, value: val });
  const setSelectedContinents = (initState) => dispatch({ type: types.SET_CONTINENTS_LIST, value: initState });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });
  const { addError } = useAPIError();
  const [currency, setCurrency] = React.useState([]);
  // start table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [noData, setNoData] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [selectedSort, setSelectedSort] = React.useState({});
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [checkedUsd, setCheckedUsd] = React.useState(false);
  const [summary, setSummary] = React.useState([]);
  const [prices, setPrices] = React.useState({});
  const [click, setClick] = React.useState('');
  const regulator = Storage.getRegulator() ?? "";
  const { filters, countryList } = useSelector(state => state.report);
  const isTradingAccount = filters.accountType === ACCOUNT_TYPES.TRADING_ACCOUNT;
  const { toggleSearchSYN } = useSearchSYN();
  const [checkedTimeDifference, setTimeDifference] = React.useState(false);
  const [dynamicColumns, setDynamicColumns] = React.useState([]);

  React.useEffect(() => {
    const displayClickId = (Storage.getDisplayClickID() === '1');
    if (displayClickId === true) {
      setDynamicColumns([{
        Header: "Click ID",
        accessor: "click_id",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, cell }) => (
          <div style={{ textAlign: "left" }}>
            {displayClickId ? cell.value : '-'}
          </div>
        )}]);
    } else {
      setDynamicColumns([]);
    }
  }, []);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Ticket ID",
          accessor: "reversal_ticket",
          disableSortBy: false,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Agent",
          accessor: "ib_name",
          disableSortBy: false,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Client Name",
          accessor: "user_name",
          disableSortBy: true,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Account",
          accessor: "mt4_account",
          disableSortBy: true,
          disableFilters: false,
          Filter: NumberComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Currency",
          accessor: "currency",
          disableSortBy: false,
          Filter: ({ column }) =>
            SelectMultipleColumnsFilter({ column }, { dropdown: currency }),
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Order Number",
          accessor: "order_number",
          disableSortBy: true,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Deposit",
          accessor: "depositCount",
          id: 'actual_amount',
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Withdraw",
          accessor: "withdrawCount",
          id: 'withdrawCount',
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Open Time",
          accessor: "create_time",
          disableSortBy: true,
          disableFilters: true,
          show: false,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Processed Time",
          accessor: "accepted_time",
          disableSortBy: true,
          disableFilters: true,
          show: false,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Last Update Time",
          accessor: "update_time",
          disableSortBy: true,
          disableFilters: true,
          show: false,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Type",
          accessor: "payment_type_text",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "PSP Name",
          accessor: "psp_name",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          disableSortBy: false,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Processed Notes",
          accessor: "processed_notes",
          disableSortBy: true,
          disableFilters: true,
          show: false,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Country",
          accessor: "country",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Reversal Time",
          accessor: "reversal_time",
          disableSortBy: false,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Reversed Type",
          accessor: "reversal_type",
          disableSortBy: false,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Internal Notes",
          accessor: "reversal_notes",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            XSSRecover(cell.value, "left")
          ),
        },
        {
          Header: "Withdraw(Apply)",
          accessor: "apply_amount",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Status(Apply)",
          accessor: "apply_status",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Callback Amount Channel",
          accessor: "callback_amount_channel",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Adjusted Amount",
          accessor: "adjusted_amount",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Id(Apply)",
          accessor: "apply_id",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Server",
          accessor: "server",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Regulator",
          accessor: "regulator",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Channel Decline Reason",
          accessor: "blocked_reason",
          disableSortBy: true,
          disableFilters: true,
          show: false,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        ...dynamicColumns,
      ], [currency, isTradingAccount, dynamicColumns]);

  const getPayments = () =>{
    const payments=[]
    if (filters.withdraw) {
      payments.push({
        dataType: filters.withdrawDataType || undefined,
        paymentStatus: filters.withdrawPaymentStatus || undefined,
        paymentType: filters.withdrawPaymentMethod || undefined,
        paymentChannel: filters.withdrawPaymentChannel || undefined,
      });
    };

    if (filters.cashAdjustment) {
      payments.push({
        dataType: filters.cashAdjustmentDataType || undefined,
      });
    };

    if (filters.deposit) {
      payments.push({
        dataType: filters.depositDataType || undefined,
        paymentStatus: filters.depositPaymentStatus || undefined,
        paymentType: filters.depositPaymentMethod || undefined,
        paymentChannel: filters.depositPaymentChannel || undefined,
      });
    };
    return payments;
  };

  const downloadFile = (file) => {
    apiDownloadFile(file)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([
          new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
          "",
          res.data],
          { type: "text/plain;charset=utf-8" }
          ));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
  };

  const getDownloadData = () => {
    setLoading(true);
    if (checkedTimeDifference){
      apiGetDepositDifferenceDownloadData({
        regulator: regulator,
        sort: selectedSort,
        filter: selectedFilter ? selectedFilter : {},
        from: filters.fromDate || "",
        to: filters.toDate || "",
        accountType: filters.accountType || undefined,
        countryCodes: filters.countryCodes || undefined,
        levelId: filters.levelId || "",
        showAll: filters.showAll,
        payments: getPayments(),
      })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            downloadFile(res.data.data.file);
          }
        })
        .catch(() => {
          setLoading(false);
      })
    } else {
    apiGetDepositDownloadData({
      regulator: regulator,
      sort: selectedSort,
      filter: selectedFilter ? selectedFilter : {},
      from: filters.fromDate || "",
      to: filters.toDate || "",
      accountType: filters.accountType || undefined,
      countryCodes: filters.countryCodes || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
      payments: getPayments(),
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          downloadFile(res.data.data.file);
        }
      })
      .catch(() => {
        setLoading(false);
	  })
    }
  };

  const getData = React.useCallback(async () => {
    try {
      const currency_res = (await apiGetCurrency(regulator)).data;
      setCurrency(currency_res.data.currency);

      const country_res = (await apiGetCountry(regulator)).data;
      setCountry(country_res.data.country);

      const mt4_account_res = (await apiGetMt4Account(regulator)).data.data.accountTypes;
      setMt4Account(Object.keys(mt4_account_res).map(key => { return {"label": key, "value": mt4_account_res[key]}}).filter((record) => record.value > 0 ));

      const currencyList = currency_res.data.currency.map(r => r.value).join(',');
      const price_res = (await apiGetCurrencyPrice(regulator, currencyList)).data;
      const currency_prices = {};
      price_res.data.price.forEach(r => {
        const key = r.symbol.replace('USD', '');

        if (r.symbol.indexOf('USD') === 0) {
          currency_prices[key] = 1 / r.price;
        } else {
          currency_prices[key] = r.price;
        }
      })

      setPrices(currency_prices);
    } catch (err) {
      console.log(err);
      setMt4Account([]);
      setCurrency([]);
      setCountry([]);
      setPrices({});
    }
  }, [regulator]);

  React.useEffect(() => {
    if (regulator) {
        getData();
    }
  }, [regulator]);

  React.useEffect(() => {
    if (click !== '') {
      toggleSearchSYN();
    }
  }, [click]);

  React.useEffect(() => {
    setTimeDifference(deposityState['timeDifference'])
  }, [deposityState]);

  let geoFund = {};
  let totalSummary = {};
  // calculate geo data and summary data
  summary.forEach(r => {
    if (r.country_iso && r.currency) {
      geoFund[r.country_iso] = geoFund[r.country_iso] || {};
      geoFund[r.country_iso][r.currency] = geoFund[r.country_iso][r.currency] || {};
      geoFund[r.country_iso][r.currency].deposit =  geoFund[r.country_iso][r.currency].deposit || 0;
      geoFund[r.country_iso][r.currency].withdraw = geoFund[r.country_iso][r.currency].withdraw || 0;
      geoFund[r.country_iso][r.currency].withdraw_apply = geoFund[r.country_iso][r.currency].withdraw_apply || 0;

      totalSummary[r.currency] = totalSummary[r.currency] || {};
      totalSummary[r.currency].deposit = totalSummary[r.currency].deposit || 0;
      totalSummary[r.currency].withdraw = totalSummary[r.currency].withdraw || 0;
      totalSummary[r.currency].withdraw_apply = totalSummary[r.currency].withdraw_apply || 0;
      totalSummary[r.currency].decimal = totalSummary[r.currency].decimal || 0;

      if (r.dataType == FUND_TYPES.DEPOSIT) {
        geoFund[r.country_iso][r.currency].deposit += r.sumAmount;
        totalSummary[r.currency].deposit += r.sumAmount;
      } else if (r.dataType == FUND_TYPES.WITHDRAW) {
        geoFund[r.country_iso][r.currency].withdraw -= r.sumAmount;
        geoFund[r.country_iso][r.currency].withdraw_apply -= r.apply_amount;
        totalSummary[r.currency].withdraw -= r.sumAmount;
        totalSummary[r.currency].withdraw_apply -= r.apply_amount;
      }

      if (totalSummary[r.currency].decimal < r.decimal) {
        totalSummary[r.currency].decimal = r.decimal;
      }
    }
  })

  // show amounts in US Dollar
  if (checkedUsd) {
    const usdFund = {};
    Object.keys(geoFund).forEach(country_iso => {
      const c = geoFund[country_iso];
      usdFund[country_iso] = usdFund[country_iso] || { USD: { deposit: 0, withdraw: 0 } };
      Object.keys(c).forEach(currency => {
        if (currency !== 'USD') {
          usdFund[country_iso].USD.deposit += c[currency].deposit * prices[currency];
          usdFund[country_iso].USD.withdraw += c[currency].withdraw * prices[currency]
          usdFund[country_iso].USD.withdraw_apply += c[currency].withdraw_apply * prices[currency]
        } else {
          usdFund[country_iso].USD.deposit += c[currency].deposit;
          usdFund[country_iso].USD.withdraw += c[currency].withdraw;
          usdFund[country_iso].USD.withdraw_apply += c[currency].withdraw_apply;
        }
      })
    });
    geoFund = usdFund;

    const usdSummary = { USD: { deposit: 0, withdraw: 0, withdraw_apply: 0 } };
    Object.keys(totalSummary).forEach(currency => {
      if (currency !== 'USD') {
        usdSummary.USD.deposit += totalSummary[currency].deposit * prices[currency];
        usdSummary.USD.withdraw += totalSummary[currency].withdraw * prices[currency];
        usdSummary.USD.withdraw_apply += totalSummary[currency].withdraw_apply * prices[currency];
      } else {
        usdSummary.USD.deposit += totalSummary[currency].deposit;
        usdSummary.USD.withdraw += totalSummary[currency].withdraw;
        usdSummary.USD.withdraw_apply += totalSummary[currency].withdraw_apply;
      }
    });
    totalSummary = usdSummary
  }

  const fetchData = ({ pageSize, pageIndex, sortParams, filterParams }) => {
    if (DateRange.FindRangeError(filters.fromDate, filters.toDate)) {
      addError(DateRange.FindRangeError(filters.fromDate, filters.toDate));
      return;
    }

    const filterOptions = {
      currency: filterParams.currency || undefined,
      agent: filterParams.ib_name || undefined,
      clientName: filterParams.user_name || undefined,
      account: filterParams.mt4_account && filterParams.mt4_account["="] ? filterParams.mt4_account["="] : (filterParams.mt4_account || undefined),
      orderNumber: filterParams.order_number || undefined,
      accounttype: seletedMt4AccountList ? seletedMt4AccountList.map(r => r.value) : undefined || undefined,
    }
    setSelectedSort(sortParams);
    setSelectedFilter(filterOptions);
    setLoading(true);
    setNoData(false);
    if (checkedTimeDifference){
        apiGetDepositDifferenceData({
          regulator: regulator,
          pageNo: pageIndex + 1,
          pageSize: pageSize,
          sort: sortParams,
          filter: filterOptions ? filterOptions : {},
          from: filters.fromDate,
          to: filters.toDate,
          accountType: filters.accountType || undefined,
          countryCodes: filters.countryCodes || undefined,
          levelId: filters.levelId || "",
          showAll: filters.showAll,
          payments: getPayments(),
        })
          .then((res) => {
            if (res.data.status === "success") {
              setData(res.data.data.deposit);
              setCount(res.data.data.total);
              setPageCount(Math.ceil(res.data.data.total / pageSize));
            }else{
              console.log("[Get Deposit Diff] status " + res.data.status);
              setNoData(true);
              setData([]);
            }
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            setNoData(true);
            setData([]);
          })
    } else {
    apiGetDepositData({
      regulator: regulator,
      pageNo: pageIndex + 1,
      pageSize: pageSize,
      sort: sortParams,
      filter: filterOptions ? filterOptions : {},
      from: filters.fromDate,
      to: filters.toDate,
      accountType: filters.accountType || undefined,
      countryCodes: filters.countryCodes || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
      payments: getPayments(),
    })
      .then((res) => {
        if (res.data.status === "success") {
          setData(res.data.data.deposit);
          setCount(res.data.data.total);
          setPageCount(Math.ceil(res.data.data.total / pageSize));
        }else{
          console.log("[Get Deposit] status " + res.data.status);
          setNoData(true);
          setData([]);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setNoData(true);
        setData([]);
      })
    }
    if (checkedTimeDifference){
      apiGetDepositDifferenceSummary({
        regulator: regulator,
        filter: filterOptions ? filterOptions : {},
        from: filters.fromDate,
        to: filters.toDate,
        accountType: filters.accountType || undefined,
        countryCodes: filters.countryCodes || undefined,
        levelId: filters.levelId || "",
        showAll: filters.showAll,
        payments: getPayments(),
      })
        .then((res) => {
          if (res.data.status === "success") {
            setSummary(res.data.data.summary);
          }
        })
        .catch((e) => {
          console.log(e);
          setSummary([]);
        })
    } else {
    apiGetDepositSummary({
      regulator: regulator,
      filter: filterOptions ? filterOptions : {},
      from: filters.fromDate,
      to: filters.toDate,
      accountType: filters.accountType || undefined,
      countryCodes: filters.countryCodes || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
      payments: getPayments(),
    })
      .then((res) => {
        if (res.data.status === "success") {
          setSummary(res.data.data.summary);
        }
      })
      .catch((e) => {
        console.log(e);
        setSummary([]);
      })
    }
  }

  const options = {};

  if (checkedUsd) {
    options.value=(node) => {
      if (checkedUsd && node && node.value && node.value.USD) {
        return node.value.USD.deposit + node.value.USD.withdraw;
      } else {
        return node;
      }
    };
    options.legends=[{
        anchor: 'bottom-left',
        direction: 'column',
        justify: true,
        translateX: 10,
        translateY: -5,
        itemsSpacing: 0,
        itemWidth: 94,
        itemHeight: 18,
        itemDirection: 'left-to-right',
        itemTextColor: '#444444',
        itemOpacity: 0.85,
        symbolSize: 18,
        effects: [{
          on: 'hover',
          style: {
            itemTextColor: '#000000',
            itemOpacity: 1
          }
        }]
    }];
  }
  const [dynamicWidth, setDynamicWidth] = React.useState(window.innerWidth);
  const classes = useStyles(dynamicWidth);

  function handleResize() {
    setDynamicWidth(window.innerWidth)
  }
  window.addEventListener('resize', handleResize)

  const resetFilter = () => {
    setSelectedCountry([]);
    setSelectedContinents([]);
    setSelectedMt4Accounts([]);
    setDeposityState(DEFAULT_DEPOSITATE_STATE);
    setUserAndLevelName("");
    setLevelId("");
    setShowAll(true);
  }

  return (
    <div className={classes.root}>
      <div className={classes.topDiv}>
        <ControlledMap
          data={geoFund}
          Tooltip={DepositSummary}
          options={options}
          onClick={(feature) => {
            const item = find(countryList, "iso", feature.id);
            if (item && item.value) {
              setSelectedCountry([item]);
              setClick(!click);
            }
          }}
        />
        <DepositSummary title="Total" data={totalSummary}/>
        <FormControlLabel
            style={{ position: 'absolute', left: 10, top: 0 }}
            control={<Switch
                      checked={checkedUsd}
                      onChange={(e) => setCheckedUsd(e.target.checked)}
                      color="primary"
                      name="checkedUsd"
                    />}
            label="Amounts in USD"
          />
      </div>
      <AdvancedTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        noData={noData}
        count={count}
        pageCount={pageCount}
        getDownloadData={getDownloadData}
        resetFilter={resetFilter}
      />
    </div>
  );
}
