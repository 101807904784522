import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import SelectFilter from "../../components/searchBar/SelectFilter";
import CountrySelect from "../../components/searchBar/CountrySelect";
import Mt4Select from "../../components/searchBar/Mt4Select";
import DateRangeFilter from "../../components/searchBar/DateRangeFilter";
import * as types from "../../constants/actionTypes";
import AddCircle from "@mui/icons-material/AddCircle";
import PaymentMethod from "./PaymentMethod";
import PaymentChannel from "./PaymentChannel";
import UserLevelFilter from "../../components/searchBar/UserLevelFilter";
import { makeStyles } from "@mui/styles";
import theme from '../../constants/theme';
import { DEFAULT_FTD_STATE } from "../../constants/constants";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
    fontStyle: "italic",
  },
  selectControl: {
    width: 250,
  },
  selectedFilters: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const DepositFilter = () => {
  const classes = useStyles();
  const {  levelId, showAll, userAndLevelName, countryList, mt4AccountList, seletedMt4AccountList, selectedCountries, ftdState } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setFilters = (filters) => dispatch({ type: types.SET_FILTERS, filters });
  const setFtdState = (val) => dispatch({ type: types.SET_FTD_STATE, value: val });
  const selectCountries = (val) => dispatch({ type: types.SET_SELECTED_COUNTRIES, value: val });
  const setSelectedContinents = (val) => dispatch({ type: types.SET_CONTINENTS_LIST, value: val });
  const setSelectedMt4Accounts = (val) => dispatch({ type: types.SET_SELECTED_MT4_ACCOUNT_LIST, value: val });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });
  const initState = {
    fromDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().clone().format("YYYY-MM-DD"),
    paymentMethod: "",
    paymentChannel: "",
    userAndLeve: "",
  };
  const [state, setState] = useState(initState);
  const filterList = [
    {
      label: "Date Range",
      value: "dateRange",
      keys: ["fromDate", "toDate"],
      required: true,
    },
    {
      label: "User & Level",
      value: "userAndLeve",
      keys: ["userAndLeve"],
      required: true,
    },
    {
      label: "MT4 Account",
      value: "mt4Account",
      keys: ["mt4Account"],
      show: true,
    },
    {
      label: "Country",
      value: "country",
      keys: ["countryCodes"],
      show: true,
    },
    {
      label: "Payment Method",
      value: "paymentMethod",
      keys: ["paymentMethod"],
      show: true,
    },
    {
      label: "Payment Channel",
      value: "paymentChannel",
      keys: ["paymentChannel"],
      show: true,
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState(
    filterList.filter((r) => r.required || r.show).map((r) => r.value)
  );

  const toggleFilter = (val) => {
    setSelectedFilters(val);
    // reset filter value if it's not selected
    const filterKeys = filterList
      .filter((r) => val.includes(r.value))
      .flatMap((r) => r.keys);
    const newFilters = { ...ftdState };

    Object.keys(ftdState).forEach((r) => {
      if (!filterKeys.includes(r)) {
        newFilters[r] = DEFAULT_FTD_STATE[r];
      }
    });
    setFtdState(newFilters);
    // reset countryCodes
    if (!filterKeys.includes('countryCodes')) {
      selectCountries([]);
      setSelectedContinents([]);
    }
    if (!filterKeys.includes('mt4Account')) {
      setSelectedMt4Accounts([]);
    }
  };

  const isVisible = (id) => {
    return selectedFilters.includes(id);
  };

  const selectedFilterCount = selectedFilters.length;

  useEffect(() => {
    const {
      fromDate,
      toDate,
      accountType,
      dataType,
      paymentMethod,
      paymentStatus,
      paymentChannel,
    } = ftdState;

    const codes = selectedCountries.map(r => r.value).join(',');
    setFilters({
      fromDate,
      toDate,
      accountType,
      dataType,
      paymentMethod,
      paymentStatus,
      paymentChannel,
      countryCodes: codes || undefined,
      levelId,
      showAll
    });
  }, [ftdState, selectedCountries, levelId, showAll]);

  return (
    <>
      <div className={classes.formControl}>
        <InputLabel id="filter-mutiple-checkbox-label">
          <AddCircle color="primary" />
        </InputLabel>
        <Select
          className={classes.selectControl}
          labelId="filter-mutiple-checkbox-label"
          id="filter-mutiple-checkbox"
          multiple
          value={selectedFilters}
          onChange={(e) => toggleFilter(e.target.value)}
          input={<Input />}
          renderValue={(selected) => `${selectedFilterCount} filters selected`}
        >
          {filterList.map((option) => (
            <MenuItem
              key={option.value}
              disabled={option.required}
              value={option.value}
            >
              <Checkbox
                color="primary"
                checked={selectedFilters.includes(option.value)}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <DateRangeFilter
        selectedFromDate={ftdState.fromDate}
        setSelectedFromDate={(val) => setFtdState({ ...ftdState, fromDate: val })}
        selectedToDate={ftdState.toDate}
        setSelectedToDate={(val) => setFtdState({ ...ftdState, toDate: val })}
      />

      {isVisible("userAndLeve") && (
        <UserLevelFilter 
          levelId={levelId}
          setLevelId={setLevelId}
          showAll={showAll}
          setShowAll={setShowAll}
          userAndLevelName={userAndLevelName}
          setUserAndLevelName={setUserAndLevelName}
        />
      )}
      {isVisible("mt4Account") && (
       <Mt4Select
          data={mt4AccountList}
         onChange={(val) => setSelectedMt4Accounts(val)}
            value={seletedMt4AccountList}
          />
      )}
      {isVisible("country") && (
        <CountrySelect
           data={countryList}
           onChange={(val) => selectCountries(val)}
           value={selectedCountries}
         />
      )}

      {isVisible("paymentMethod") && (
        <PaymentMethod
          value={ftdState.paymentMethod}
          dataType={ftdState.dataType}
          onChange={(val) =>
            setFtdState({ ...ftdState, paymentMethod: val, paymentChannel: "" })
          }
        />
      )}

      {isVisible("paymentChannel") && (
        <PaymentChannel
          value={ftdState.paymentChannel}
          dataType={ftdState.dataType}
          paymentMethod={ftdState.paymentMethod}
          onChange={(val) => setFtdState({ ...ftdState, paymentChannel: val })}
        />
      )}
    </>
  );
};

export default DepositFilter;
