import React, { useState, useCallback, useEffect } from "react";
import { CSVLink } from "react-csv";
import { apiGetCampaign } from "../../resource/index.js";
import Storage from "../../common/lib/storage";
import DataGrid from '../../components/DataGrid';
import NoDataComponent from "../../components/table/NoDataComponent";
import TokenInvalidComponent from "../../components/table/TokenInvalidComponent";
import useAUTH from '../../common/hooks/useAUTH';
import DownloadButton from "../../components/toolbar/DownloadButton";
import CampaignDetail from "./CampaignDetail";
import moment from "moment";
import { XSSRecover, XSSRecoverText } from "../../util/xssHelper";

const customStyles = {
  headCells: {
    style: {
      fontSize: '1rem',
      fontWeight: 'bold',
      backgroundColor: "white",
    },
  }
};

const columns = [
  { selector: 'referee_id', name: 'Referee ID', sortable: true },
  { selector: 'referee_name',
    name: 'Referee',
    sortable: true,
    format: (row) => XSSRecover(row.referee_name)},
  { selector: 'referrer_id', name: 'Referrer ID', sortable: true },
  { selector: 'referrer_name',
    name: 'Referrer',
    sortable: true,
    format: (row) => XSSRecover(row.referrer_name)},
  { selector: 'status', name: 'Status', sortable: true},
  { selector: 'waiting_condition', name: 'Waiting Condition', sortable: '40%'},
];

const headers = columns.map(r => ({ label: r.name, key: r.selector}));

const Download = ({ data }) => {
  let csvLink;
  const timestamp = moment().clone().format("YYYYMMDDHHmmss");
  const filename = `campaign_report_${timestamp}.csv`;
  const getDownloadData = () => {
    csvLink.link.click();
  }

  const data_xss = data.map(item => ({
    ...item,
    referee_name: XSSRecoverText(item.referee_name),
    referrer_name: XSSRecoverText(item.referrer_name)
  }));

  return (<>
    <DownloadButton getDownloadData={getDownloadData} />
    <CSVLink style={{ display: "none" }} data={data_xss} headers={headers} filename={filename} ref={(r) => csvLink = r}>Download</CSVLink>
  </>);
}

export default function CampaignReport() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const regulator = Storage.getRegulator() ?? "";
  const { tokenInvalid } = useAUTH();
  const searchRole = Storage.getSearchRole();
  const downloadRole = Storage.getDownloadRole();
  
  const fetchData = useCallback(() => {
    setLoading(true);
    setRows([]);
    const campaignReportPermitted = Storage.getCampaignReportRole();
    if (campaignReportPermitted) {
    apiGetCampaign(regulator)
      .then((res) => {
        if (res.data.status === "success") {
          res.data.data.campaign.forEach(r => r.status = r.status.replace('_', ' '));
          setRows(res.data.data.campaign);
        }
        setLoading(false); 
      })
      .catch((e) => { setLoading(false); })
    } else {
      setLoading(false);
    }
  }, [regulator]);
  
  useEffect(() => {
    if (regulator) {
      if (searchRole) {
        fetchData();
      } else {
        setLoading(false);
      }
    }
  }, [regulator]);
  
  return (
    <div className="campaignReport">
      {tokenInvalid ? (
            <TokenInvalidComponent length={columns.length} />
          ) : (
        <div>
        <DataGrid
           columns={columns}
           data={rows}
           ExpandableComponent={({ data }) => <CampaignDetail data={data} />}
           progressPending={loading}
           props={{
             noDataComponent: <NoDataComponent length={columns.length} />,
             header: { subHeader: true, subHeaderAlign: 'right'},
             actions: (downloadRole && <Download data={rows} />),
             pointerOnHover: true,
             expandOnRowClicked: true,
             customStyles
           }}
        />
        </div>)
      }
    </div>
  );
}
