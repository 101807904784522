import React, { useEffect, useState } from "react";
import SelectFilter from '../../components/searchBar/SelectFilter';
import MenuItem from "@mui/material/MenuItem";
import { apiGetPayment } from "../../resource/index.js";
import Storage from "../../common/lib/storage";

const PaymentMethod = ({
  value,
  dataType,
  onChange
}) => {
  const [paymentList, setPaymentList] = useState([]);
  const regulator = Storage.getRegulator() ?? "";
  
  useEffect(() => {
    if (dataType) {
      apiGetPayment(regulator, dataType)
        .then((res) => {
          if (res.data.status === "success") {
            setPaymentList(res.data.data.payment);
            onChange('')
          }
        })
        .catch((e) => {
          setPaymentList([]);
        })
    } else {
      setPaymentList([]);
    }
  }, [regulator, dataType]);

  return (
      <SelectFilter
          size="small"
          select
          variant="outlined"
          label="Payment Method"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            value: value || "",
            onChange: (e) => onChange(e.target.value || ""),
            displayEmpty: true,
          }}
      >
          <MenuItem value=""><em>All Methods</em></MenuItem>
          {paymentList.map(r => <MenuItem key={r.p_value} value={r.p_value}>{r.p_content}</MenuItem>)}
      </SelectFilter>
  )
}

export default PaymentMethod;