import React from "react";
import { ResponsiveChoropleth } from '@nivo/geo';
import countries from './geojson/countries.json';
import areas from './geojson/areas.json';

export default function GeoMap({
  data,
  Tooltip,
  onClick,
  options,
  scale,
  translationX,
  translationY
}) {
  const geoData = Object.keys(data).map(r => ({id: r, value: data[r]}));
  const spots = geoData.map(r => areas[r.id]).filter(r => r);
  const features = [...countries.features, ...spots];
  
  return (
    <ResponsiveChoropleth
        data={geoData}
        features={features}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        colors="RdYlGn"
        domain={[ -500000, 600000 ]}
        valueFormat=".2s"
        unknownColor="#dddddd"
        label="properties.name"
        projectionScale={scale}
        projectionTranslation={[ translationX, translationY ]}
        projectionRotation={[ 0, 0, 0 ]}
        enableGraticule={true}
        graticuleLineColor="rgba(0, 0, 0, .2)"
        borderWidth={0.5}
        borderColor="#101b42"
        tooltip={(input) => {
          const c = data[input.feature.id];
          if (c && Tooltip) {
            return <Tooltip title={input.feature.properties.name} data={c} />
          } else {
            return ""
          }
        }}
        onClick={(feature) => {
          if (onClick) {
            onClick(feature)
          }
        }}
        {...options}
    />
  )
}
