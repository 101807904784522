import React from "react";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { DefaultColumnFilter } from "./filterTypes/filterTypes";
import NoDataComponent from "./table/NoDataComponent";
import TokenInvalidComponent from "./table/TokenInvalidComponent";
import BasicToolBar from "./toolbar/BasicToolBar";
import StyledTableCell from "./table/StyledTableCell";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useAUTH from "../common/hooks/useAUTH";
import useSearchSYN from "../common/hooks/useSearchSYN";
import theme from '../constants/theme';

const useStyles = makeStyles(() => ({
  sortSection: {
    display: "inline",
    verticalAlign: "middle",
  },
  tableWrapper: {
    borderRadius: "20px",
    marginTop: theme.spacing(2),
    width: "100%",
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  noDataWrapper: {
    borderRadius: "20px",
    marginTop: theme.spacing(2),
    width: "100%",
  },
  headerRow: {
    // borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#fafafa",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tableDiv: {
    width: (dynamicWidth) => dynamicWidth - theme.spacing(39).replace('px', ''),
    overflowX: "auto",
  },
}));

function AdvancedTable({
  columns,
  data,
  fetchData,
  loading,
  noData,
  count,
  pageCount: controlledPageCount,
  searchBar,
  getDownloadData,
  getCustomFooter,
  invisibleColumns,
  resetFilter,
}) {
  const [dynamicWidth, setDynamicWidth] = React.useState(window.innerWidth);
  const classes = useStyles(dynamicWidth);
  const [pagination, setPagination] = React.useState(0);
  const { tokenInvalid } = useAUTH();
  const { searchSYN } = useSearchSYN();

  const defaultColumn = React.useMemo(
    () => ({
      // set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  function handleResize() {
    setDynamicWidth(window.innerWidth)
  }
  window.addEventListener('resize', handleResize)

  // Use the state and functions returned from useTable to build UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    footerGroups,
    page,
    // which has only the rows for the active page
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, sortBy },
    setAllFilters,
    visibleColumns,
    toggleHideColumn
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      initialState: {
        pageIndex: 0,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
        }).concat(invisibleColumns),
      },
      autoResetHiddenColumns: false,
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      manualSortBy: true,
      autoResetPage: false,
      manualFilters: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
    setPagination(0);
    gotoPage(0);
  };

  const handleResetFilter = () => {
    setPagination(0);
    setPageSize(10);
    setAllFilters([]);
    gotoPage(0);
    resetFilter();
  };

  const firstUpdate = React.useRef(true);
  const currentSearchSYN = React.useRef(searchSYN);
  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    // Skip the first load
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const triggeredBySearch = currentSearchSYN.current != searchSYN;
    if (triggeredBySearch && pagination !== 0){
      setPagination(0);
      gotoPage(0);
      return;
    }

    let sortParams =
      Object.assign({}, ...sortBy.map(({ id, desc }) => ({ [id]: desc }))) ??
      {};
    let filterParams =
      Object.assign({}, ...filters.map(({ id, value }) => ({ [id]: value }))) ??
      {};

    fetchData({
      pageIndex: triggeredBySearch ? 0 : pageIndex,
      pageSize: pageSize,
      sortParams,
      filterParams,
    });

    currentSearchSYN.current = searchSYN;
  }, [pageIndex, pageSize, sortBy, searchSYN]);

  // Render the UI
  return (
    <>
      <Paper className={noData? classes.noDataWrapper: classes.tableWrapper}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <BasicToolBar
          allColumns={allColumns}
          setAllFilters={setAllFilters}
          searchBar={searchBar}
          getDownloadData={getDownloadData}
          handleResetFilter={handleResetFilter}
          toggleHideColumn={toggleHideColumn}
        />
        <div className={classes.tableDiv}>
        <MaUTable {...getTableProps()}>
          {tokenInvalid ? (
            <TokenInvalidComponent length={visibleColumns.length} />
          ) : (
            <>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow
                    {...headerGroup.getHeaderGroupProps()}
                    className={(classes.headerRow, classes.noWrap)}
                  >
                    {headerGroup.headers.map((column) => (
                      <StyledTableCell
                        {...column.getHeaderProps()}
                        align="left"
                      >
                        <div {...column.getSortByToggleProps()}>
                          {column.alias || column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <div className={classes.sortSection}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <KeyboardArrowUpIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              )
                            ) : column.canSort ? (
                              // <SortIcon fontSize="small" />
                              ""
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* Render the columns filter UI */}
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              {noData ? (
                <NoDataComponent length={visibleColumns.length} />
              ) : (
                <TableBody {...getTableBodyProps()} className={classes.noWrap}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()} hover>
                        {row.cells.map((cell) => {
                          return (
                            <StyledTableCell {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </StyledTableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </>
          )}
          <TableFooter>
            {!noData && getCustomFooter && getCustomFooter(visibleColumns)}
            {/*footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
                ))*/}
          </TableFooter>
        </MaUTable>
        </div>
        {/* pagination section */}
        {!noData && (
          <div className="pagination">
            <TablePagination
              component="div"
              count={count}
              page={pagination}
              showFirstButton
              showLastButton
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </>
  );
}

export default AdvancedTable;
