function NumberFormatter(val, min=4, max=4) {
  return val
    ? val.toLocaleString(undefined, {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
      })
    : "-";
}

function find(arr, key, val) {
  for (let i=0; i<arr.length; i++) {
    if (arr[i][key] === val) {
      return arr[i];
    }
  }

  return null;
}

function getUniqueArray(arr) {
  return [...new Set(arr)]
}

export { NumberFormatter, find, getUniqueArray };
