import React, { useState, useCallback } from 'react';

export const SearchSYNContext = React.createContext({
    searchSYN: 0,
    toggleSearchSYN: () => {}
  });
  
export default function SearchSYNProvider({ children }) {
  const [searchSYN, setSearchSYN] = useState(0);

  const toggleSearchSYN = () => {
    setSearchSYN(searchSYN ? 0 : 1);
    };

  const contextValue = {
    searchSYN,
    toggleSearchSYN: useCallback(() => toggleSearchSYN(), [searchSYN])
  };

  return (
    <SearchSYNContext.Provider value={contextValue}>
      {children}
    </SearchSYNContext.Provider>
  );
}