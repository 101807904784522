import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Routes from "./Routes";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  filterForm: {
    display: "flex",
    margin: 10,
    flexDirection: "column",
  },
}));

const getRouteFilter = (location) => {
  const curr = Routes.filter((r) => r.path == location.pathname)[0];

  if (curr && curr.filter) {
    return <curr.filter /> || "";
  }

  return "";
};

const ReportFilter = () => {
  const classes = useStyles();
  const filters = useSelector((state) => state.report.filters);

  return (
    <div className={classes.filterForm}>{getRouteFilter(useLocation())}</div>
  );
};

export default ReportFilter;
