import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import { makeStyles } from "@mui/styles";
import theme from '../../constants/theme';

const useStyles = makeStyles(() => ({
    select:{
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const getColourStyles = (isFocused) => {
    return {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({ 
            ...styles, 
            margin: '20px 0 0 15px',
            backgroundColor: 'white',
            borderRadius: '10px',
            backgroundColor: isSelected ? "#ffffff": "",
            boxShadow: null,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return { 
                ...styles,
                borderBottom: data['groupName'] !== undefined ? '0.1mm solid rgb(220, 220, 220, .6);' : '',
                fontStyle: data['groupName'] !== undefined ? 'italic' : 'initial', 
                textAlign: data['groupName'] !== undefined ? 'left' : 'left',
                fontSize: data['groupName'] !== undefined ? '25px' : '16px',
                fontWeight: data['groupName'] !== undefined ? 'normal' : 'normal',
                height: data['selected'] !== undefined ? '' : '32px',
                position: 'relative',
                verticalAlign: 'middle',
                backgroundColor: isFocused && isSelected ? "#0080ff" : isFocused ? "#e6f2ff" :  isSelected ? '#66b3ff' : isDisabled ? 'red' : "#ffffff",
                color: '#000000',
                cursor: 'default',
                isFocused: "#99ccff",
                borderRadius: '0',
            }
        },
        valueContainer:(styles, state) => {
            return { 
                ...styles, 
                maxHeight: isFocused ? "180px" : "60px",
                overflow: 'auto',
                overflowY: "scroll",
                overflowX: "hidden",
                scrollbarWidth: "thin",
            }
        },
        input:(styles, state) => {
            return { 
                ...styles, 
                margin: '0px',
            }
        },
        indicatorSeparator: state => ({
            display: 'true',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '43px',
        }),
        dropdownIndicator: (styles,  { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            paddin: "0",

        }),
        clearIndicator: (styles) => ({
            ...styles,
            margin: "0 -8px 0 0",
            padding: "0",
        }),
    }
};

const MySelect = ({
    groups,
    groupsList,
    options,
    isMulti,
    closeMenuOnSelect,
    hideSelectedOptions,
    components,
    onChange,
    placeholder,
    allowSelectAll,
    value,
}) => {
    const classes = useStyles();
    const [isFoucsed, setIsFoucsed] = React.useState(false);
    return (
    <ReactSelect
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        components={components}
        placeholder={placeholder}
        allowSelectAll={allowSelectAll}
        value={value}
        styles={getColourStyles(isFoucsed)}
        className={classes.select}
        options={[...options]}
        isSearchable={true}
        pageSize={5}
        onFocus={(selected, event) => {
            setIsFoucsed(true)
        }}
        onBlur={(selected, event) => {
            setIsFoucsed(false)
        }}
        onChange={(selected, event) => {
            if ( selected === null ) {
                return onChange([])
            }
            if ( event.action==='select-option' && selected !== null && selected.length > 0 && groupsList.indexOf(selected[selected.length - 1].value) > -1) {
                // clear options in selected where contains groups['continent']
                selected = selected.filter((record) => groups[selected[selected.length - 1].value].indexOf(record) < 0 )
                return onChange([ ...selected, ...groups[selected[selected.length - 1].value]] );
            }
            if (event.action==='deselect-option' && groupsList.indexOf(event.option.value) > -1 ){
                selected = selected.filter((record) => record.continent !== event.option.value )
            }
            if ( event.action==='remove-value' && groupsList.indexOf(event.removedValue.value) > -1 ) {
                selected = selected.filter((record) => record.continent !== event.removedValue.value )
            }
            return onChange(selected);
        }}
    />
    );
  };

MySelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default MySelect;