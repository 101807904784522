import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import SelectFilter from "../../components/searchBar/SelectFilter";
import DateRangeFilter from "../../components/searchBar/DateRangeFilter";
import * as types from "../../constants/actionTypes";
import AddCircle from "@mui/icons-material/AddCircle";
import UserLevelFilter from "../../components/searchBar/UserLevelFilter";
import { makeStyles } from "@mui/styles";
import theme from "../../constants/theme";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
    fontStyle: "italic",
    "& .MuiSvgIcon-root": {
      marginRight: "10px",
    },
  },
  selectControl: {
    width: 250,
  },
  selectedFilters: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const RebateFilter = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const setFilters = (filters) =>
    dispatch({ type: types.SET_FILTERS, filters });
  const {
    levelId,
    showAll,
    userAndLevelName,
    dataSourceList,
    currencyList,
    rebateState,
  } = useSelector((state) => state.report);
  const setRebateState = (val) =>
    dispatch({ type: types.SET_REBATE_STATE, value: val });
  const setUserAndLevelName = (val) =>
    dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) =>
    dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) =>
    dispatch({ type: types.SET_SHOW_ALL, value: val });
  const filterList = [
    {
      label: "Date Range",
      value: "dateRange",
      keys: ["fromDate", "toDate"],
      required: true,
      required: true,
    },
    {
      label: "User & Level",
      value: "userAndLeve",
      keys: ["userAndLeve"],
      required: true,
    },
    {
      label: "Server",
      value: "dataSource",
      keys: ["dataSource"],
      show: true,
    },
    {
      label: "Trading On Credit",
      value: "tradeOnCredit",
      keys: ["tradeOnCredit"],
      show: true,
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState(
    filterList.filter((r) => r.required || r.show).map((r) => r.value)
  );

  const toggleFilter = (val) => {
    setSelectedFilters(val);
    // reset filter value if it's not selected
    const filterKeys = filterList
      .filter((r) => val.includes(r.value))
      .flatMap((r) => r.keys);
    const newFilters = { ...rebateState };

    Object.keys(rebateState).forEach((r) => {
      if (!filterKeys.includes(r)) {
        newFilters[r] = "";
      }
    });
    setRebateState(newFilters);
  };

  const isVisible = (id) => {
    return selectedFilters.includes(id);
  };

  useEffect(() => {
    const { fromDate, toDate, dataSource, tradeOnCredit } = rebateState;
    setFilters({
      fromDate,
      toDate,
      dataSource,
      tradeOnCredit,
      levelId,
      showAll,
    });
  }, [rebateState, levelId, showAll]);

  return (
    <>
      <div className={classes.formControl}>
        <InputLabel id="filter-mutiple-checkbox-label">
          <AddCircle color="primary" />
        </InputLabel>
        <Select
          className={classes.selectControl}
          labelId="filter-mutiple-checkbox-label"
          id="filter-mutiple-checkbox"
          multiple
          value={selectedFilters}
          onChange={(e) => toggleFilter(e.target.value)}
          input={<Input />}
          renderValue={(selected) => `${selected.length} filters selected`}
        >
          {filterList.map((option) => (
            <MenuItem
              key={option.value}
              disabled={option.required}
              value={option.value}
            >
              <Checkbox
                color="primary"
                checked={selectedFilters.includes(option.value)}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <DateRangeFilter
        selectedFromDate={rebateState.fromDate}
        setSelectedFromDate={(val) =>
          setRebateState({ ...rebateState, fromDate: val })
        }
        selectedToDate={rebateState.toDate}
        setSelectedToDate={(val) =>
          setRebateState({ ...rebateState, toDate: val })
        }
      />
      {isVisible("userAndLeve") && (
        <UserLevelFilter
          levelId={levelId}
          setLevelId={setLevelId}
          showAll={showAll}
          setShowAll={setShowAll}
          userAndLevelName={userAndLevelName}
          setUserAndLevelName={setUserAndLevelName}
        />
      )}

      {isVisible("dataSource") && (
        <SelectFilter
          size="small"
          select
          variant="outlined"
          label="Server"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            value: rebateState.dataSource || "",
            onChange: (e) =>
              setRebateState({
                ...rebateState,
                dataSource: e.target.value || "",
              }),
            displayEmpty: true,
          }}
        >
          <MenuItem key={-1} value="">
            <em>All Servers</em>
          </MenuItem>
          {dataSourceList.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </SelectFilter>
      )}
      {isVisible("tradeOnCredit") && (
        <SelectFilter
          size="small"
          select
          variant="outlined"
          label="Rebate"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            value: rebateState.tradeOnCredit || "",
            onChange: (e) =>
              setRebateState({
                ...rebateState,
                tradeOnCredit: e.target.value || "",
              }),
            displayEmpty: true,
          }}
        >
          <MenuItem value="">
            <em>All Rebates</em>
          </MenuItem>
          <MenuItem value="0">Normal</MenuItem>
          <MenuItem value="1">Trading On Credit</MenuItem>
        </SelectFilter>
      )}
    </>
  );
};

export default RebateFilter;
