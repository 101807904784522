import React from 'react';
import DataTable from 'react-data-table-component';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircularProgress from "@mui/material/CircularProgress";

export default function DataGrid({
  props,
  columns,
  data,
  ExpandableComponent,
  progressPending
}) {
  const subComponent = ExpandableComponent? <ExpandableComponent /> : undefined
  
  return (
     <DataTable
          {...props}
          columns={columns}
          data={data}
          highlightOnHover
          pagination
          expandableRows={!!subComponent}
          expandableRowsComponent={subComponent}
          sortIcon={<KeyboardArrowDownIcon />}
          progressPending={progressPending}
          progressComponent={<CircularProgress color="primary" />}
      />
  );
}
