import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles, withStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignContent: 'center',
        padding: 2,
        color: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid #e0e0e0',
        fontWeight: 600,
        borderRadius: 20
    },
    selected: {
        color: '#fff',
        borderRadius: 20,
        backgroundColor: '#1976d2'
    }
  }));

  const SwitchButton = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: 'none',
      lineHeight: 1.5,
      width: 120,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        boxShadow: 'none',
        color: '#fff',
        borderRadius: 20,
        backgroundColor: '#1976d2',
      },
      '&:active': {
        boxShadow: 'none',
        color: '#fff',
        borderRadius: 20,
        backgroundColor: '#1976d2',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
      },
    },
  })(Button);

export default function SwitchSelect({
    value,
    setValue,
    leftItem,
    rightItem
}) {
    const classes = useStyles();
    const onChangeLeft = () => {
        setValue(true);
    }
    const onChangeRight = () => {
        setValue(false);
    }

    return (<div className={classes.wrapper}>
        <SwitchButton variant="outlined" onClick={onChangeLeft} className={value? classes.selected: ''}>{leftItem.label}</SwitchButton>
        <SwitchButton variant="outlined" onClick={onChangeRight} className={!value? classes.selected: ''}>{rightItem.label}</SwitchButton>
    </div>)
}