import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
      background: {
        default: "#fff",
      },
    },
    typography: {
      fontFamily: ["Nunito", "Roboto", '"Helvetica Neue"', "Arial"].join(","),
    },
  });

export default theme;