import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {NumberFormatter} from "../../util/tableHelper";

const FTDSummary = ({data}) => {
  var len = Object.entries(data).length;
  return Object.entries(data).map((r, i) =>
    <TableRow key={i}>
      {i === 0 && (<TableCell style={{fontSize: '1.5rem', fontWeight: 'bold'}} rowSpan={len} colSpan={3} align='center'>Total</TableCell>)}
      {r.map((item, index) => <TableCell style={{fontSize: '1rem', fontWeight: 'bold'}} key={index} align='left'>{
              item
          }</TableCell>)}
    </TableRow>
  )
}

export default FTDSummary;
