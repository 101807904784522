import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { NumberFormatter} from "../../util/tableHelper";

const TradeSummary = ({data}) => {
  return  (
      <>
        <TableRow>
          <TableCell style={{fontSize: '1.5rem', fontWeight: 'bold'}} align='center'>Total</TableCell>
          <TableCell style={{fontSize: '1rem', fontWeight: 'bold'}} colSpan={2} align='left'>Volume: {NumberFormatter(data.volume,2,4)}</TableCell>
          <TableCell style={{fontSize: '1rem', fontWeight: 'bold'}} colSpan={2} align='left'>Commission: {NumberFormatter(data.commission,2,2)}</TableCell>
          <TableCell style={{fontSize: '1rem', fontWeight: 'bold'}} colSpan={2} align='left'>Interest: {NumberFormatter(data.interest,2,2)}</TableCell>
          <TableCell style={{fontSize: '1rem', fontWeight: 'bold'}} colSpan={2} align='left'>Profit/Loss: {NumberFormatter(data.profit,2,2)}</TableCell>
        </TableRow>
    </>    
   ) 
}

export default TradeSummary;