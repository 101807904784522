import React from "react";
import { makeStyles } from "@mui/styles";
import GeoMap from './GeoMap';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import DirectionsIcon from '@mui/icons-material/Directions';
import Slider from '@mui/material/Slider';
import Popover from '@mui/material/Popover';
import Fab from '@mui/material/Fab';

const useStyles = makeStyles(() => ({
    root: {
      width: '80%',
      height: 500,
      position: 'relative',
      paddingTop: 20
    },
    tool: {
        position: 'absolute',
        left: '60%',
        top: 0,
        width: 135,
        display: 'flex',
        justifyContent: 'space-between'
    }
  }));

export default function ControlledMap({
    data,
    Tooltip,
    onClick,
    options,
}) {
    const classes = useStyles();

    const [scale, setScale] = React.useState(100);
    const [translationX, setTranslationX] = React.useState(0.35);
    const [translationY, setTranslationY] = React.useState(0.63);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
      };

    return (
        <div className={classes.root}>
          <GeoMap
              data={data}
              Tooltip={Tooltip}
              options={options}
              onClick={onClick}
              scale={scale}
              translationX={translationX}
              translationY={translationY}
            />
          <div className={classes.tool}>
            <Fab onClick={() => setScale(scale >= 200? 210: scale + 10)} size="small" color="primary" aria-label="add">
                <AddIcon />
            </Fab>
            <Fab onClick={() => setScale(scale <= 10? 0: scale - 10)} size="small" color="primary" aria-label="add">
                <MinusIcon />
            </Fab>
            <Fab size="small" color="primary" aria-label="add">
            <DirectionsIcon onClick={handleClick} />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', margin: 15 }}>
                <FormControlLabel
                    style={{ width: 200 }}
                    control={<Slider
                    value={translationX}
                    step={0.05}
                    min={0}
                    max={1}
                    onChange={(event, newValue) => setTranslationX(newValue)}
                    aria-labelledby="continuous-slider" />}
                    label="Move Left/Right"
                    labelPlacement={'top'}
                />
                <FormControlLabel
                    style={{ width: 200 }}
                    control={<Slider
                    value={translationY}
                    step={0.05}
                    min={0}
                    max={1}
                    onChange={(event, newValue) => setTranslationY(newValue)}
                    aria-labelledby="continuous-slider" />}
                    label="Move Up/Down"
                    labelPlacement={'top'}
                />
                </div>
            </Popover>
            </Fab> 
        </div>
      </div>
    )
}