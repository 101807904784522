import { withStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import theme from '../../constants/theme';

const SelectFilter = withStyles(() => ({
  root: {
    float: "left",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    width: "91%",
    "& > *": {
      fontWeight: 600,
    },
    "& label.Mui-focused": {
      color: theme.palette.getContrastText(grey[500]),
      border: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: theme.palette.getContrastText(grey[500]),
        border: `1px solid  ${grey[300]}`,
        borderRadius: "10px",
      },
      "&:hover fieldset": {
        color: theme.palette.getContrastText(grey[500]),
        border: `1px solid  ${grey[300]}`,
        borderRadius: "10px",
      },
      "&.Mui-focused fieldset": {
        color: theme.palette.getContrastText(grey[500]),
        border: `1px solid  ${grey[300]}`,
        borderRadius: "10px",
      },
    },
  },
}))(TextField);

export default SelectFilter;
