import React from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Clear from '@mui/icons-material/Clear';
import theme from '../../constants/theme';

const useStyles = makeStyles(() => ({
    inputField: {
        "& .MuiFilledInput-root": {
            background: "white"
        },
        width: '100%'
    },
    icon: {
      color: theme.palette.text.primary
    },
  }));

const RangeInputField = ({
    value,
    onChange
  }) => {
    const classes = useStyles();
    const [left, setLeft] = React.useState(value? value[">="] || '' : '');
    const [right, setRight] = React.useState(value? value["<="] || '' : '');
    const clearLeft = () => {
      setLeft('');
    }
    const clearRight = () => {
      setRight('');
    }
  
    React.useEffect(() => {
      if (left === '' && right === '') {
        onChange('');
      } else {
        onChange({">=": left, "<=": right});
      }
    }, [left, right])
  
    return <div>
      <TextField
        id="filter-box-value"
        size="small"
        variant="filled"
        label="lower value"
        value={left}
        onChange={e => setLeft(e.target.value)}
        className={classes.inputField}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: <IconButton onClick={clearLeft}><Clear className={classes.icon} /></IconButton>
        }}
      />
      <TextField
        id="filter-box-value"
        size="small"
        variant="filled"
        label="upper value"
        value={right}
        onChange={e => setRight(e.target.value)}
        className={classes.inputField}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: <IconButton onClick={clearRight}><Clear className={classes.icon} /></IconButton>
        }}
      />
    </div>
  }

  export default RangeInputField;