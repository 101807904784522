import React from "react";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import theme from '../../constants/theme';

const useStyles = makeStyles(() => ({
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ChannelFilter({
  data,
  channel,
  setChannel,
  channelError,
}) {
  React.useEffect(() => {
    setChannel(data[0]?.value);
  }, [data, setChannel]);
  const classes = useStyles();

  const handleChange = (event) => {
    setChannel(event.target.value);
  };

  return (
    <TextField
      id="outlined-select-channel"
      select
      className={classes.select}
      label="Channel"
      value={channel || ""}
      onChange={handleChange}
      error={channelError ? true : false}
      helperText={channelError}
      variant="outlined"
    >
      {data.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
