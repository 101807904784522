import DOMPurify from 'dompurify';

function XSSRecover( value, textAlign ) {
  const sanitizedValue = DOMPurify.sanitize(value);

  return (
    <div style={{ textAlign: textAlign }} dangerouslySetInnerHTML={{ __html: sanitizedValue }}></div>
  );
}

function XSSRecoverText( encodedHtml ) {
  const sanitizedValue = DOMPurify.sanitize(encodedHtml);

  const text_string = document.createElement("textarea");
  text_string.innerHTML = sanitizedValue;

  return text_string.value;
}

export { XSSRecover, XSSRecoverText };
