import { useMemo } from "react";
import axios from "axios";
import useAPIError from "../common/hooks/useAPIError";
import useAUTH from "../common/hooks/useAUTH";
import Storage from "../common/lib/storage"
import ErrorHandler from "../common/lib/errorHandler"

const instance = axios.create();

const WithAxios = ({ children }) => {
  const { addError } = useAPIError();
  const { makeTokenInvalid } = useAUTH();
  useMemo(() => {
    instance.interceptors.request.use(
      (config) => {
        config.withCredentials = true;
        config.headers.Authorization =
          "Bearer " + Storage.getToken()
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response) {
          switch (error.response.status) {
            case 406:
            case 403:
              let err = error.response.data.data;
              if (err?.message)
                addError(
                  ErrorHandler.convertErrMsg(err.message),
                  error.response.status
                );
              break;
            case 401:
              makeTokenInvalid();
              break;
            default:
          }
        }
        console.log(error);
        return Promise.reject(error);
      }
    );
  }, [addError, makeTokenInvalid]);

  return children;
};

export { WithAxios, instance };
