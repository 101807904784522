import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as types from '../../constants/actionTypes';
import moment from "moment";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import NormalTextField from "./NormalTextField";
import SelectField from "./SelectField";
import FilterBox from "./FilterBox";
import theme from '../../constants/theme';

const useStyles = makeStyles(() => ({
  selectWrapper: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: -10,
      width: 140,
    },
  },
  inputWrapper: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: -10,
      minWidth: 140,
    },
  },
}));

function submitHandler(e) {
  e.preventDefault();
}

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  const classes = useStyles();
  return (
    <form className={classes.inputWrapper} noValidate autoComplete="off" onSubmit={submitHandler}>
      <NormalTextField
        size="small"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={""}
      />
    </form>
  );
}

function NumberEqualFilter({ column: { filterValue, setFilter } }) {
  const classes = useStyles();
  return (
    <form className={classes.inputWrapper} noValidate autoComplete="off" onSubmit={submitHandler}>
      <NormalTextField
        size="small"
        inputProps={{
          pattern: "[0-9]*",
        }}
        value={filterValue ? filterValue["="] : ""}
        onChange={(e) => {
          if (e.target.value === "") {
            setFilter(undefined);
          } else if (e.target.validity.valid) {
            setFilter({ "=": e.target.value });
          }
          // Set undefined to remove the filter entirely
        }}
        placeholder={""}
      />
    </form>
  );
}

function StringComboFilter({ column: { id, filterValue, setFilter } }) {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch();
  const setFilters = (filters) => dispatch({ type: types.SET_FILTERS, filters });
  const { filters } = useSelector((state) => state.report);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    let newFilter = {};
    if (filters.filter) {
      newFilter = { ...filters.filter, [id]: filterValue }
    } else {
      newFilter = { [id]: filterValue }
    }
    setFilters({ filter : newFilter });

    if (!filterValue) {
      setValue('');
      return;
    }
    
    const originalVal = Object.values(filterValue)[0];

    if (Array.isArray(originalVal)) {
      setValue(originalVal.join(' or '));
    } else {
      setValue(originalVal);
    }
 
  }, [filterValue])

  return (
    <form className={classes.inputWrapper} noValidate autoComplete="off" onSubmit={submitHandler}>
      <NormalTextField
        size="small"
        value={value}
        placeholder={""}
        onClick={handleClick}
        InputProps={{
          readOnly: true,
        }}
      />
      <FilterBox type='string' value={filterValue} onChange={setFilter}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}  />
    </form>
  );
}

function NumberComboFilter({ column: { id, filterValue, setFilter } }) {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch();
  const setFilters = (filters) => dispatch({ type: types.SET_FILTERS, filters });
  const { filters } = useSelector((state) => state.report);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    let newFilter = {};
    if (filters.filter) {
      newFilter = { ...filters.filter, [id]: filterValue }
    } else {
      newFilter = { [id]: filterValue }
    }
    setFilters({ filter : newFilter });

    if (!filterValue) {
      setValue('');
      return;
    }

    const originalVal = Object.values(filterValue)[0];

    if (Array.isArray(originalVal)) {
      setValue(originalVal.join(' or '));
      return;
    } else if (typeof originalVal === 'object') {
      setValue(Object.keys(originalVal)
                .filter(r => originalVal[r])
                .map(r => `${r} ${originalVal[r]}`).join(' and '));  
    } else {
      setValue(originalVal);
    }
  }, [filterValue])

  return (
    <form className={classes.inputWrapper} noValidate autoComplete="off" onSubmit={submitHandler}>
      <NormalTextField
        size="small"
        value={value}
        placeholder={""}
        onClick={handleClick}
        InputProps={{
          readOnly: true,
        }}
      />
      <FilterBox type='number' value={filterValue} onChange={setFilter}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}  />
    </form>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const classes = useStyles();
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <TextField
      size="small"
      select
      className={classes.selectWrapper}
      displayEmpty
      SelectProps={{
        value: filterValue || "",
        onChange: (e) => {
          setFilter(e.target.value || undefined);
        },
      }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

// This is a custom filter UI for selecting
// multiple options from a list
function SelectMultipleColumnsFilter(
  { column: { filterValue, setFilter, preFilteredRows, id } },
  { dropdown }
) {
  const selectAll = "All";
  const classes = useStyles();
  const dispatch = useDispatch();
  const setFilters = (filters) => dispatch({ type: types.SET_FILTERS, filters });
  const { filters } = useSelector((state) => state.report);

  React.useEffect(() => {
    let newFilter = {};

    if (filters.filter) {
      newFilter = { ...filters.filter, [id]: filterValue }
    } else {
      newFilter = { [id]: filterValue }
    }
    
    setFilters({ filter : newFilter });
  }, [filterValue]);
  // Options for filtering
  const options = React.useMemo(() => {
    const options = dropdown;
    return options;
  }, [dropdown]);

  const [checkedAll, setCheckedAll] = useState(dropdown !== undefined && filterValue !== undefined && dropdown.length === filterValue.length);
  const onSelectAll = (e) => {
    setFilter(checkedAll ? [] : options.map((v) => v["value"]));
    setCheckedAll(!checkedAll);
  };

  const renderValue = (selected) => {
    if (checkedAll) return "All selected";

    const selectedLen = selected.length;
    if (!selectedLen) return;

    return `${selectedLen} of ${options.length} selected`;
  };
  // Render a multi-select box
  return (
    <SelectField
      size="small"
      select
      variant="outlined"
      className={classes.selectWrapper}
      SelectProps={{
        multiple: true,
        value: filterValue || [],
        onChange: (e, c) => {
          const clicked = c.props.value;
          // Will be handled by onSelectAll
          if (clicked === selectAll) return;
          const selectedArr = e.target.value;
          setCheckedAll(selectedArr.length >= options.length); // Tick select all if the rest are all ticked, un-tick it if any of the rest is not ticked
          setFilter(selectedArr || undefined);
        },
        renderValue: renderValue,
      }}
    >
      {options.length > 0 && (
        <MenuItem value={selectAll} onClick={onSelectAll}>
          <Checkbox color="primary" checked={checkedAll} />
          <ListItemText primary={selectAll} />
        </MenuItem>
      )}
      {options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          <Checkbox
            color="primary"
            checked={filterValue ? filterValue.includes(option.value) : false}
          />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </SelectField>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

// This is a custom UI for date range
// filter. It uses two boxes and filters rows to
// ones that have values between the two
function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  // const dates = preFilteredRows.map((val) =>
  //   moment(val.original[id], "MM DD YYYY")
  // );
  // const minDate = moment.min(dates).subtract(1, "day"); // To include the date
  // const maxDate = moment.max(dates).add(1, "day");
  return (
    <React.Fragment>
      <Flatpickr
        className="form-control"
        value={filterValue || ""}
        onChange={(date) => {
          if (date.length === 2) {
            setFilter([
              moment(new Date(date[0])).format("YYYY-MM-DD"),
              moment(new Date(date[1])).format("YYYY-MM-DD"),
            ]);
          }
        }}
        options={{
          mode: "range",
        }}
      />
    </React.Fragment>
  );
}

function dateRange(rows, id, filterValue) {
  console.log(filterValue);
  let start = moment(filterValue[0]).subtract(1, "day").format("YYYY-MM-DD");
  let end = moment(filterValue[1]).add(1, "day").format("YYYY-MM-DD");

  return rows.filter((val) =>
    moment(val.original[id], "YYYY-MM-DD").isBetween(
      moment(start, "YYYY-MM-DD", moment(end, "YYYY-MM-DD"))
    )
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export {
  DefaultColumnFilter,
  StringComboFilter,
  NumberComboFilter,
  SelectColumnFilter,
  NumberRangeColumnFilter,
  filterGreaterThan,
  DateRangeColumnFilter,
  SelectMultipleColumnsFilter,
  dateRange,
  NumberEqualFilter,
};
