import React from "react";
import ChannelFilter from "./ChannelFilter";
import { useDropzone } from "react-dropzone";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import AttachFile from "@mui/icons-material/AttachFile";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Report from "@mui/icons-material/Report";
import Typography from "@mui/material/Typography";
import CloudDone from "@mui/icons-material/CloudDone";
import theme from '../../constants/theme';

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#676b69";
  }
  if (props.isDragActive) {
    return "#676b69";
  }
  return "#eeeeee";
};

export default function ChannelDropZone({ handleSubmit, channelData }) {
  const [files, setFiles] = React.useState([]);
  const [channel, setChannel] = React.useState();
  const [completed, setCompleted] = React.useState(false);
  const [channelError, setChannelError] = React.useState("");
  React.useEffect(() => {
    setCompleted(false);
  }, [channel]);

  const onDrop = (acceptedFiles) => {
    setFiles([]);
    setCompleted(false);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        setFiles(acceptedFiles);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const uploadFiles = async (files) => {
    for (const file of files) {
      file["successful"] = await handleSubmit(file, channel);
    }
  };

  const handleClick = async () => {
    if (!channel) {
      setChannelError("Channel is Required!");
      return;
    } else {
      setChannelError("");
    }
    const filesToHandle = [...files];
    await uploadFiles(filesToHandle);
    setFiles(filesToHandle);
    setCompleted(true);
  };

  const handleOK = () => {
    setFiles([]);
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop: onDrop, accept: ".csv", multiple: false });

  const useStyles = makeStyles(
    () => {
      return {
        files: {
          backgroundColor: theme.palette.background.paper,
        },
        centerBtn: {
          display: "block",
          margin: "auto",
          width: 120,
        },
        successBtn: {
          backgroundColor: "green",
        },
        success: {
          color: "green",
        },
        dropZone: {
          flex: "1",
          display: "flex",
          height: "200px",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          borderWidth: "2px",
          borderRadius: "2px",
          borderColor: getColor({ isDragActive, isDragReject, isDragAccept }),
          borderStyle: "dashed",
          backgroundColor: "#fafafa",
          color: "#bdbdbd",
          outline: "none",
          transition: "border .24s ease-in-out",
        },
        nestedItem: {
          paddingLeft: theme.spacing(4),
        },
        nestedList: {
          marginTop: theme.spacing(-3),
        },
      };
    },
    { isDragActive, isDragReject, isDragAccept }
  );
  const classes = useStyles();

  const acceptedFileItems = files.map((file) => {
    const successful = file["successful"];
    return (
      <div className={classes.files}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AttachFile />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${file.path} - ${(file.size / 1024).toFixed(2)} kb`}
              secondary={
                <Typography
                  variant="body2"
                  className={successful === true ? classes.success : null}
                >
                  {`Upload ${
                    successful === false
                      ? "failed"
                      : successful === true
                      ? "successful"
                      : "awaiting"
                  }`}
                </Typography>
              }
            />
            {!file["successful"] ? (
              <Report />
            ) : (
              <CloudDone className={classes.success} />
            )}
          </ListItem>
        </List>
      </div>
    );
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div className={classes.files}>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AttachFile />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${file.path} - ${(file.size / 1024).toFixed(2)} kb`}
          />
          <Report />
        </ListItem>
      </List>
      {errors.map((e) => (
        <List className={classes.nestedList}>
          <ListItem button className={classes.nestedItem}>
            <ListItemIcon>
              <ErrorOutline />
            </ListItemIcon>
            <ListItemText primary={e.message} />
          </ListItem>
        </List>
      ))}
    </div>
  ));

  return (
    <section>
      <ChannelFilter
        data={channelData}
        setChannel={setChannel}
        channelError={channelError}
        channel={channel}
      />
      <div {...getRootProps({ className: classes.dropZone })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop channel files here, or click to select files</p>
        <em>(Only csv file will be accepted)</em>
      </div>
      <aside>
        {!!files.length && (
          <div>
            <h4>Accepted files</h4>
            <ul>{acceptedFileItems}</ul>
            {completed ? (
              <Button
                variant="contained"
                size="large"
                className={classes.centerBtn}
                onClick={handleOK}
              >
                OK
              </Button>
            ) : (
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.centerBtn}
                onClick={handleClick}
              >
                Upload
              </Button>
            )}
          </div>
        )}
        {!!fileRejections.length && (
          <div>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
          </div>
        )}
      </aside>
    </section>
  );
}
