import * as React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
// web.cjs is required for IE11 support
import { useSpring, animated } from "react-spring/web.cjs";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../toolbar/PrimaryButton";
import ToolBarButton from "../toolbar/ToolBarButton";
import SwitchSelect from '../searchBar/SwitchSelect';
import Storage from "../../common/lib/storage";
import { apiGetUserLevelTree } from "../../resource/index.js";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  bgcolor: "#eeeeee",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
  "& .footer": {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    button: {
      minWidth: "90px",
      marginLeft: "12px",
    },
    ".selected_wrapper": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .header": {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    button: {
      minWidth: "90px",
    },
  },
};

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default function CustomizedTreeView({
  open,
  handleClose,
  setNodeId,
  nodeId,
  name,
  setName,
  showAll,
  setShowAll
}) {
  const regulator = Storage.getRegulator() ?? "";
  const [expanded, setExpanded] = React.useState(["root"]);
  const [selectedTextToSubmit, setSelectedTextToSubmit] = React.useState(name);
  const [selectedNodeToSubmit, setSelectedNodeToSubmit] = React.useState("");
  const [treeData, setTreeData] = React.useState({});
  const [availableData, setAvailableData] = React.useState('');
  const [disabledSubmit, setDisabledSubmit] = React.useState(treeData.id === nodeId);
  const [nameFilter, setNameFilter] = React.useState("");


  const fetchData = React.useCallback(async () => {
    const res = await apiGetUserLevelTree({
      regulator
    });
    
    setTreeData(res.data.data.tree);
    setAvailableData(res.data.data.tree);
  }, [regulator]);

  React.useEffect(() => {
    fetchData();
  }, [regulator]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, value) => {
    setDisabledSubmit(treeData.tnode.data.id === value);
    if (treeData.tnode.data.id === value) {
      setSelectedTextToSubmit('');
      console.log('root cannot be selected')
      return
    }
    setSelectedNodeToSubmit(value);
    setSelectedTextToSubmit(event.target.outerText);
  };

  const renderTree = (nodes) => (
    <StyledTreeItem key={nodes.tnode.data.id} nodeId={nodes.tnode.data.id} label={nodes.tnode.data.name}>
      {Array.isArray(nodes.tnode.children)
        ? nodes.tnode.children.map((node) => renderTree(node))
        : null}
    </StyledTreeItem>
  );

  const handleCancel = () => {
    if (!nodeId) {
      setName("");
    }
    setSelectedTextToSubmit(name);
    handleClose();
  };

  const handleReset = () => {
    setName("");
    setSelectedTextToSubmit("");
    setNodeId("");
    setNameFilter("");
    setAvailableData(treeData);
    setDisabledSubmit(treeData.tnode.data.id === nodeId);
    setShowAll(true)
    handleClose();
  };

  const handleSubmit = () => {
    setNodeId(selectedNodeToSubmit);
    setName(selectedTextToSubmit);
    handleClose();
  };

  const handleSearch = () => {
    const target = nameFilter.trim();
    const result = JSON.parse(JSON.stringify(treeData));

    const search = (node) => {
      if (node.tnode.data.name.toLowerCase().includes(target.toLowerCase())) {
        return true;
      }
      // check if the current node's descendants include the target
      if (node.tnode.children && node.tnode.children.length > 0) {
        let children = [];

        for (let n of node.tnode.children) {
          if (search(n)) {
            children.push(n);
          }
        }
        node.tnode.children = children;
  
        return node.tnode.children.length > 0;
      }

      return false;
    }

    if (target === '' || search(result)) {
      setAvailableData(result);
    } else {
      setAvailableData('');
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className="header">
          <SwitchSelect
            leftItem={{label: 'All Levels'}}
            rightItem={{label: 'Direct Levels'}}
            value={showAll}
            setValue={setShowAll}
            />
          <div className="search_wrapper">
            <TextField
              id="standard-basic"
              variant="standard"
              placeholder="Search"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              />
            <PrimaryButton onClick={handleSearch}>Search</PrimaryButton>
          </div>
        </div>
        <TreeView
          aria-label="customized"
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          sx={{ height: 264, flexGrow: 1, maxWidth: '100%', overflowY: "auto" }}
          expanded={expanded}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          selected={nodeId}
        >
          {availableData === ''? (<div>no data found</div>) : renderTree(availableData)}
        </TreeView>
        <div className="footer">
          <div className="selected_wrapper">Selected: {selectedTextToSubmit}</div>
          <div>
            <ToolBarButton onClick={handleCancel}>Cancel</ToolBarButton>
            <PrimaryButton onClick={handleReset}>Reset</PrimaryButton>
            <PrimaryButton onClick={handleSubmit} disabled={disabledSubmit}>Confirm</PrimaryButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
