import moment from "moment";

const dateRangeLimit = 365;

const getDatesDiff = (from, to) => {
    const a = moment(from);
    const b = moment(to);
    return b.diff(a, 'days');
}

class DateRange {
  /**
   * Returns a error message optionally
   *
   * @return {string} returns a error message if the date range is invalid, otherwise return null
   */
    static FindRangeError(from, to) {
        if (getDatesDiff(from, to) < 0) {
            return "From date cannot be greater than To date"
        }
        if (getDatesDiff(from, to) + 1 > dateRangeLimit) {
            return `Date range is beyond ${dateRangeLimit} days`;
        }
    }
}
export default DateRange;