import notFound from "../../assets/img/notFound.png";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

const NoDataComponent = ({ length }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={length} style={{ textAlign: "center" }}>
          <img src={notFound} alt="img" />
          <Typography variant="h4">No Results</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default NoDataComponent;
