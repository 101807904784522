import React, { useState, useCallback } from 'react';

export const AUTHContext = React.createContext({
    tokenInvalid: false,
    makeTokenInvalid: () => {}
  });
  

export default function AUTHProvider({ children }) {
  const [tokenInvalid, setTokenInvalid] = useState(false);

  const makeTokenInvalid = () => setTokenInvalid(true);

  const contextValue = {
    tokenInvalid,
    makeTokenInvalid: useCallback(() => makeTokenInvalid(), [])
  };

  return (
    <AUTHContext.Provider value={contextValue}>
      {children}
    </AUTHContext.Provider>
  );
}