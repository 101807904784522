import { withStyles } from "@mui/styles";
import { pink } from "@mui/material/colors";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import theme from '../../constants/theme';

const StyledButton = withStyles(() => ({
  root: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    borderRadius: "20px",
    "&:hover": {
      border: `1px solid  ${pink[300]}`,
      backgroundColor: pink[300],
    },
  },
}))(Button);

function DownloadButton({ getDownloadData }) {
  return (
    <StyledButton
      startIcon={<CloudDownloadIcon />}
      onClick={() => {
        getDownloadData();
      }}
    >
      Download
    </StyledButton>
  );
}

export default DownloadButton;
