import React from "react";
import Storage from "../../common/lib/storage";
import useAUTH from "../../common/hooks/useAUTH";
import TokenInvalidComponent from "../../components/table/TokenInvalidComponent";
import { apiChannelUpload, apiGetChannelList } from "../../resource/index.js";
import ChannelDropZone from "./ChannelDropZone";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  circular: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function ChannelData() {
  const regulator = Storage.getRegulator() ?? "";
  const { tokenInvalid } = useAUTH();
  const [channels, setChannels] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    apiGetChannelList().then((res) => {
      if (res.data.status === "success") {
        let channel = res.data.data.channel;
        channel.forEach((item) => {
          if (item["value"].toLowerCase() === "solid")
            item["label"] = "SOLIDPAY";
          if (item["value"].toLowerCase() === "solidpay")
            item["label"] = "SOLIDAY(MISCODED)";
        });
        setChannels(channel);
      }
    });
  }, []);

  const handleSubmit = async (file, channel) => {
    setLoading(true);
    // Process file
    let formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("regulator", regulator);
    formData.append("channel", channel);

    let data;
    try {
      data = (await apiChannelUpload(formData)).data;
    } catch {
    } finally {
      setLoading(false);
    }
    return data && data.status === "success" ? true : false;
  };

  return (
    <div className="ChannelData">
      {tokenInvalid ? (
        <TokenInvalidComponent />
      ) : (
        <div>
          {loading && (
            <CircularProgress size={80} className={classes.circular} />
          )}
          <div style={{ textAlign: "left" }}>
            <ChannelDropZone
              handleSubmit={handleSubmit}
              channelData={channels}
            />
          </div>
        </div>
      )}
    </div>
  );
}
