import postman from "../../assets/img/postman.png";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

const TokenInvalidComponent = ({ length }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={length} style={{ textAlign: "center" }}>
          <img src={postman} alt="img" />
          <Typography variant="h4">
            Your session has expired, please go back to admin
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default TokenInvalidComponent;
