import React, { useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { apiGetRebateSummaryData } from "../../resource/index.js";
import { NumberFormatter, find, getUniqueArray } from "../../util/tableHelper";
import useSearchSYN from "../../common/hooks/useSearchSYN";
import { LOTS_SUFFIX } from "../../constants/constants";

const renderSummaryRow = (total) => {
   return total.map((r, i) =>
     <TableRow key={i}>
        {i === 0 && (<TableCell style={{fontSize: '1.5rem', fontWeight: 'bold'}} rowSpan={total.length} colSpan={2} align='center'>Total</TableCell>)}
        {r.map((item, index) => <TableCell style={{fontSize: '1rem', fontWeight: 'bold'}} key={index} align='left'>{item}</TableCell>)}
     </TableRow>
   )
}

const RebateSummary = ({
  visibleColumns,
  filterNames,
  regulator,
  from,
  to,
  dataSource,
  tradeOnCredit,
  levelId,
  showAll
}) => {
  const { searchSYN } = useSearchSYN();
  const { filters } = useSelector((state) => state.report);
  const [summary, setSummary] = useState([]);
  const fetchData = useCallback(() => {
    setSummary([]);
    const validFilters = {};
    
    filterNames.forEach(r => {
      if (filters.filter[r]) {
        validFilters[r] = filters.filter[r];
      }
    })
    apiGetRebateSummaryData({
      regulator,
      filter: validFilters,
      from,
      to,
      dataSource: dataSource || undefined,
      tradeOnCredit: tradeOnCredit || undefined,
      levelId: levelId || "",
      showAll: showAll,
    }).then((res) => {
      if (res.data.status === "success") {
        setSummary(res.data.data.summary)
      }
    })
  }, [regulator, filters.filter, from, to, dataSource, tradeOnCredit, levelId, showAll]);

  React.useEffect(() => {
    if (regulator && from && to) {
      fetchData();
    }
  }, [searchSYN]);

  const currency = getUniqueArray(summary.flatMap(r => Object.keys(r)))
    .filter(r => r !== 'group_name' && r !== 'group_label' && !r.includes(LOTS_SUFFIX)).sort();
  const fixedColumns = ["Name", "Account", "Currency"];
  const cols = visibleColumns.flatMap(col => fixedColumns.includes(col.Header)? [] : col.Header).filter(col => !col.includes(LOTS_SUFFIX));
  const total = currency.map(r => {
    const vals = [r];
    cols.forEach(name => {
      const group = find(summary, 'group_label', name);
      group && (group[r] || group[r] === 0) ? vals.push(NumberFormatter(group[r])): vals.push("-");
      const lots_label = r + LOTS_SUFFIX;
      group && (group[lots_label] || group[lots_label] === 0) ? vals.push(NumberFormatter(group[lots_label],2,2)): vals.push("-");
    })
    return vals;
  })
  // fill columns with hyphens if total is not available
  if (total.length === 0) {
    total.push(Array((cols.length + 1)*2).fill('-'))
  }

  return <>{renderSummaryRow(total)}</>
}

export default RebateSummary;