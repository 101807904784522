import { withStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";

const StyledTableCell = withStyles((theme) => ({
  head: { fontSize: "16px", fontWeight: 600 },
  body: {
    fontSize: 15,
  },
}))(TableCell);

export default StyledTableCell;
