import React from "react";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import theme from '../../constants/theme';

const useStyles = makeStyles(() => ({
  datePickerFrom: {
    float: "left",
    display: "inline-block",
    width: "180px",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    "& .MuiFormControl-marginNormal": {
      marginTop: theme.spacing(-1),
    },

    "& .MuiInputBase-input": { fontWeight: 600, fontStyle: "italic" },
  },
  datePickerTo: {
    float: "left",
    display: "inline-block",
    width: "180px",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    "& .MuiFormControl-marginNormal": {
      marginTop: theme.spacing(-1),
    },

    "& .MuiInputBase-input": { fontWeight: 600, fontStyle: "italic" },
  },
}));

const format = "YYYY-MM-DD"; // note: it's different from datepicker format below

const DateRangeFilter = ({
  selectedFromDate,
  setSelectedFromDate,
  selectedToDate,
  setSelectedToDate,
}) => {
  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={classes.datePickerFrom}>
        <DatePicker
          autoOk
          placeholder="2021-01-01"
          label="From"
          value={selectedFromDate}
          onChange={(newValue) => {
            setSelectedFromDate(moment(newValue).format(format));
          }}
          format="yyyy-MM-dd"
          inputFormat="yyyy-MM-dd"
          renderInput={(params) => <TextField {...params} />}
          maxDate={new Date()}
        />
      </div>
      <div className={classes.datePickerTo}>
        <DatePicker
          autoOk
          placeholder="2021-01-31"
          label="To"
          value={selectedToDate}
          onChange={(newValue) => {
            setSelectedToDate(moment(newValue).format(format));
          }}
          format="yyyy-MM-dd"
          inputFormat="yyyy-MM-dd"
          renderInput={(params) => <TextField {...params} />}
          maxDate={new Date()}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DateRangeFilter;
