import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import MultiSelectBase from "../../components/searchBar/MultiSelectBase";
import SelectFilter from '../../components/searchBar/SelectFilter';
import CountrySelect from '../../components/searchBar/CountrySelect';
import DateRangeFilter from '../../components/searchBar/DateRangeFilter';
import {
  ACCOUNT_TYPES,
  DEFAULT_CCWITHDRAWDETAIL_STATE,
} from "../../constants/constants";
import * as types from '../../constants/actionTypes';
import AddCircle from '@mui/icons-material/AddCircle';
import WithdrawStatus from './CCWithdrawDetailStatus';
import UserLevelFilter from "../../components/searchBar/UserLevelFilter";
import { makeStyles } from "@mui/styles";
import theme from '../../constants/theme';


const useStyles = makeStyles(() => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
    fontStyle: "italic",
  },
  selectControl: {
    width: 250,
  },
  selectedFilters: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const CCWithdrawDetailFilter = () => {
  const classes = useStyles();
  const {
          filters,
          levelId,
          showAll,
          userAndLevelName,
          mt4AccountList,
          seletedMt4AccountList,
          countryList,
          selectedCountries,
          ccWithdrawDetailState,
          ccWithdrawDetailChannelList,
          seletedCCWithdrawDetailChannelList,
          seletedCCWithdrawDetailStatus,
  } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setFilters = (filters) => dispatch({ type: types.SET_FILTERS, filters });
  const selectCountries = (val) => dispatch({ type: types.SET_SELECTED_COUNTRIES, value: val });
  const setCCWithdrawDetailState = (val) => dispatch({ type: types.SET_CCWITHDRAWDETAIL_STATE, value: val });
  const setSelectedMt4Accounts = (val) => dispatch({ type: types.SET_SELECTED_MT4_ACCOUNT_LIST, value: val });
  const setSelectedContinents = (val) => dispatch({ type: types.SET_CONTINENTS_LIST, value: val });
  const setSelectedCCDetailChannels = (val) => dispatch({ type: types.SET_SELECTED_CCWITHDRAWDETAIL_CHANNEL_LIST, value: val });
  const setSelectedCCDetailStatus = (val) => dispatch({ type: types.SET_SELECTED_CCWITHDRAWDETAIL_STATUS, value: val });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });

  const filterList = [
    {
      label: "Date Range",
      value: "dateRange",
      keys: ["fromDate", "toDate"],
      required: true,
    },
    {
      label: "User & Level",
      value: "userAndLeve",
      keys: ["userAndLeve"],
      required: true,
    },
    {
      label: "Account Type",
      value: "accountType",
      keys: ["accountType"],
      required: true,
    },
    {
      label: "Country",
      value: "country",
      keys: ["countryCodes"],
      show: true,
    },
    {
      label: "MT4 Account",
      value: "mt4Account",
      keys: ["mt4Account"],
      show: true,
    },
    {
      label: "Credit Card Channels",
      value: "ccSelect",
      keys: ["ccSelect"],
      show: true,
    },
    {
      label: "Withdraw Status",
      value: "ccWithdrawStatus",
      keys: ["ccWithdrawStatus"],
      show: true,
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState(
    filterList.filter((r) => r.required || r.show).map((r) => r.value)
  );

  const toggleFilter = (val) => {
    setSelectedFilters(val);
    // reset filter value if it's not selected
    const filterKeys = filterList
      .filter((r) => val.includes(r.value))
      .flatMap((r) => r.keys);
    const newFilters = { ...ccWithdrawDetailState};
    Object.keys(ccWithdrawDetailState).forEach((r) => {
      if (!(filterKeys.includes(r))) {
        newFilters[r] = DEFAULT_CCWITHDRAWDETAIL_STATE[r];
      }
    });
    console.log("new filters: " + newFilters)
    setCCWithdrawDetailState(newFilters)
    // reset countryCodes
    if (!filterKeys.includes('countryCodes')) {
      selectCountries([]);
      setSelectedContinents([]);
    }
    if (!filterKeys.includes('mt4Account')) {
      setSelectedMt4Accounts([]);
    }
  };

  const isVisible = (id) => {
    return selectedFilters.includes(id);
  };

  const selectedFilterCount = selectedFilters.length;

  useEffect(() => {
    const {
      fromDate,
      toDate,
      accountType,
      seletedCCWithdrawDetailStatus,
      seletedCCWithdrawDetailChannelList,
    } = ccWithdrawDetailState;

    const codes = selectedCountries.map(r => r.value).join(',');
    setFilters({
      fromDate,
      toDate,
      accountType,
      seletedCCWithdrawDetailStatus,
      seletedCCWithdrawDetailChannelList,
      countryCodes: codes || undefined,
      levelId,
      showAll
    });
  }, [ccWithdrawDetailState, seletedCCWithdrawDetailStatus, seletedCCWithdrawDetailChannelList, selectedCountries, levelId, showAll]);

  return (
    <>
      <div className={classes.formControl}>
        <InputLabel id="filter-mutiple-checkbox-label">
          <AddCircle color="primary" />
        </InputLabel>
        <Select
          className={classes.selectControl}
          labelId="filter-mutiple-checkbox-label"
          id="filter-mutiple-checkbox"
          multiple
          value={selectedFilters}
          onChange={(e) => toggleFilter(e.target.value)}
          input={<Input />}
          renderValue={(selected) => `${selectedFilterCount} filters selected`}
        >
          {filterList.map((option) => (
            <MenuItem
              key={option.value}
              disabled={option.required}
              value={option.value}
            >
              <Checkbox
                color="primary"
                checked={selectedFilters.includes(option.value)}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <DateRangeFilter
        selectedFromDate={ccWithdrawDetailState.fromDate}
        setSelectedFromDate={(val) => setCCWithdrawDetailState({ ...ccWithdrawDetailState, fromDate: val })}
        selectedToDate={ccWithdrawDetailState.toDate}
        setSelectedToDate={(val) => setCCWithdrawDetailState({ ...ccWithdrawDetailState, toDate: val })}
      />

      {isVisible("userAndLeve") && (
          <UserLevelFilter
            levelId={levelId}
            setLevelId={setLevelId}
            showAll={showAll}
            setShowAll={setShowAll}
            userAndLevelName={userAndLevelName}
            setUserAndLevelName={setUserAndLevelName}
          />
        )}

      <SelectFilter
        size="small"
        select
        variant="outlined"
        label="Account Type"
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          value: ccWithdrawDetailState.accountType || "",
          onChange: (e) =>
            setCCWithdrawDetailState({ ...ccWithdrawDetailState, accountType: e.target.value || "" }),
          displayEmpty: true,
        }}
      >
        <MenuItem value="">
          <em>All Account Types</em>
        </MenuItem>
        <MenuItem value={ACCOUNT_TYPES.TRADING_ACCOUNT}>
          Trading Account
        </MenuItem>
        <MenuItem value={ACCOUNT_TYPES.REBATE_ACCOUNT}>Rebate Account</MenuItem>
      </SelectFilter>

      {isVisible("mt4Account") && (
      <MultiSelectBase
          data={mt4AccountList}
          onChange={(val) => setSelectedMt4Accounts(val)}
          value={seletedMt4AccountList}
          default_value="All Accounts"
          id="multiple-country-select"
          label="MT4 Account"
      />
      )}

      {isVisible("country") && (
        <CountrySelect
           data={countryList}
           onChange={(val) => selectCountries(val)}
           value={selectedCountries}
         />
      )}

      {isVisible("ccWithdrawStatus") && (
      <WithdrawStatus
          value={ccWithdrawDetailState.seletedCCWithdrawDetailStatus}
          dataType={2}
          onChange={(val) => {
            setCCWithdrawDetailState({ ...ccWithdrawDetailState, seletedCCWithdrawDetailStatus: val})
            setSelectedCCDetailStatus(val)
          }}
      />
      )}

      {isVisible("ccSelect") && (
      <MultiSelectBase
          data={ccWithdrawDetailChannelList}
          onChange={(val) => {
            setCCWithdrawDetailState({ ...ccWithdrawDetailState, seletedCCWithdrawDetailChannelList: val})
            setSelectedCCDetailChannels(val)
          }}
          value={ccWithdrawDetailState.seletedCCWithdrawDetailChannelList}
          default_value="All Channels"
          id="multiple-cc-channel-select"
          label="Credit Card Channels"
      />
      )}

    </>
  );
};

export default CCWithdrawDetailFilter;
