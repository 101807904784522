import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import theme from '../../constants/theme';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    "& > *": {
      fontWeight: "600",
    },
    "& label": {
      fontWeight: "600",
      color: "rgba(0, 0, 0, 0.6)",
    },
    "& label.Mui-focused": {
      color: theme.palette.getContrastText(grey[500]),
      border: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: theme.palette.getContrastText(grey[500]),
        border: `1px solid  ${grey[300]}`,
        borderRadius: "10px",
      },
      "&:hover fieldset": {
        color: theme.palette.getContrastText(grey[500]),
        border: `1px solid  ${grey[300]}`,
        borderRadius: "10px",
      },
      "&.Mui-focused fieldset": {
        color: theme.palette.getContrastText(grey[500]),
        border: `1px solid  ${grey[300]}`,
        borderRadius: "10px",
      },
    },
  },
}));

export default function Mt4Select({ data, onChange, value }) {
  const classes = useStyles();
  const [placeholder, setPlaceholder] = useState("All Accounts");
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        size="small"
        limitTags={1}
        id="multiple-country-select"
        value={value || []}
        options={data}
        getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => {
          if (newValue && newValue.length > 0) {
            setPlaceholder("All Accounts");
            onChange(newValue);
          } else {
            setPlaceholder("All Accounts");
            onChange([]);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="MT4 Account"
            placeholder={placeholder}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </div>
  );
}
