import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Mt4Select from "../../components/searchBar/Mt4Select";
import SelectFilter from '../../components/searchBar/SelectFilter';
import CountrySelect from '../../components/searchBar/CountrySelect';
import DateRangeFilter from '../../components/searchBar/DateRangeFilter';
import {
  ACCOUNT_TYPES,
  DEFAULT_TRADE_STATE,
} from "../../constants/constants";
import * as types from '../../constants/actionTypes';
import AddCircle from '@mui/icons-material/AddCircle';
import UserLevelFilter from "../../components/searchBar/UserLevelFilter";
import { makeStyles } from "@mui/styles";
import theme from '../../constants/theme';
import Mt4ServerSelect from "../../components/searchBar/Mt4ServerSelect"
import TradeActionSelect from "../../components/searchBar/TradeActionSelect"

const useStyles = makeStyles(() => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
    fontStyle: "italic",
  },
  selectControl: {
    width: 250,
  },
  selectedFilters: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const TradeFilter = () => {
  const classes = useStyles();
  const { filters, levelId, showAll, userAndLevelName, mt4AccountList, seletedMt4AccountList, mt4ServerList, seletedMt4ServerList, actionList, seletedActionList, countryList, selectedCountries, tradeState } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setFilters = (filters) => dispatch({ type: types.SET_FILTERS, filters });
  const selectCountries = (val) => dispatch({ type: types.SET_SELECTED_COUNTRIES, value: val });
  const setSelectedMt4Accounts = (val) => dispatch({ type: types.SET_SELECTED_MT4_ACCOUNT_LIST, value: val });
  const setSelectedContinents = (val) => dispatch({ type: types.SET_CONTINENTS_LIST, value: val });
  const setTradeState = (val) => dispatch({ type: types.SET_TRADE_STATE, value: val });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setSelectedMt4Servers = (val) => dispatch({ type: types.SET_SELECTED_MT4_SERVER_LIST, value: val });
  const setSelectedActions = (val) => dispatch({ type: types.SET_SELECTED_ACTION_LIST, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });

  const filterList = [
    {
      label: "Date Range",
      value: "dateRange",
      keys: ["fromDate", "toDate"],
      required: true,
    },
    {
      label: "User & Level",
      value: "userAndLeve",
      keys: ["userAndLeve"],
      required: true,
    },
    {
      label: "agentAccount",
      value: "agentAccount",
      keys: ["agentAccount"],
      show: true,
    },
    {
      label: "MT4 Server",
      value: "mt4Server",
      keys: ["mt4Server"],
      show: true,
    },
    {
      label: "Trade Action",
      value: "action",
      keys: ["action"],
      show: true,
    },
    {
      label: "Country",
      value: "country",
      keys: ["countryCodes"],
      show: true,
    },    
    {
      label: "MT4 Account",
      value: "mt4Account",
      keys: ["mt4Account"],
      show: true,
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState(
    filterList.filter((r) => r.required || r.show).map((r) => r.value)
  );

  const toggleFilter = (val) => {
    setSelectedFilters(val);
    // reset filter value if it's not selected
    const filterKeys = filterList
      .filter((r) => val.includes(r.value))
      .flatMap((r) => r.keys);
    const newFilters = { ...tradeState};
    Object.keys(tradeState).forEach((r) => {
      if (!(filterKeys.includes(r) )) {
        newFilters[r] = DEFAULT_TRADE_STATE[r];
      }
    });
    setTradeState(newFilters)
      // reset countryCodes
      if (!filterKeys.includes('countryCodes')) {
        selectCountries([]);
        setSelectedContinents([]);
      }
      if (!filterKeys.includes('mt4Account')) {
        setSelectedMt4Accounts([]);
      }
      if (!filterKeys.includes('mt4Server')) {
        setSelectedMt4Servers([]);
      }
      if (!filterKeys.includes('action')) {
        setSelectedActions([]);
      }
  };

  const isVisible = (id) => {
    return selectedFilters.includes(id);
  };

  const selectedFilterCount = selectedFilters.length;

  useEffect(() => {
    const {
      fromDate,
      toDate,
      accountType,
      action,
    } = tradeState;
    
    const codes = selectedCountries.map(r => r.value).join(',');
    setFilters({
      fromDate,
      toDate,
      accountType,
      action,
      countryCodes: codes || undefined,      
      levelId,
      showAll,
    });
  }, [tradeState, selectedCountries, levelId, showAll]);

  return (
    <>
      <div className={classes.formControl}>
        <InputLabel id="filter-mutiple-checkbox-label">
          <AddCircle color="primary" />
        </InputLabel>
        <Select
          className={classes.selectControl}
          labelId="filter-mutiple-checkbox-label"
          id="filter-mutiple-checkbox"
          multiple
          value={selectedFilters}
          onChange={(e) => toggleFilter(e.target.value)}
          input={<Input />}
          renderValue={(selected) => `${selectedFilterCount} filters selected`}
        >
          {filterList.map((option) => (
            <MenuItem
              key={option.value}
              disabled={option.required}
              value={option.value}
            >
              <Checkbox
                color="primary"
                checked={selectedFilters.includes(option.value)}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <DateRangeFilter
        selectedFromDate={tradeState.fromDate}
        setSelectedFromDate={(val) => setTradeState({ ...tradeState, fromDate: val })}
        selectedToDate={tradeState.toDate}
        setSelectedToDate={(val) => setTradeState({ ...tradeState, toDate: val })}
      />

      {isVisible("userAndLeve") && (
          <UserLevelFilter 
            levelId={levelId}
            setLevelId={setLevelId}
            showAll={showAll}
            setShowAll={setShowAll}
            userAndLevelName={userAndLevelName}
            setUserAndLevelName={setUserAndLevelName}            
          />
        )}
        
      {isVisible("mt4Server") && (
        <Mt4ServerSelect
            data={mt4ServerList}
            onChange={(val) => setSelectedMt4Servers(val)}
            value={seletedMt4ServerList}
        />
      )}

      <SelectFilter
        size="small"
        select
        variant="outlined"
        label="Account Type"
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          value: tradeState.accountType || "",
          onChange: (e) =>
            setTradeState({ ...tradeState, accountType: e.target.value || "" }),
          displayEmpty: true,
        }}
      >
        <MenuItem value=""><em>All Account Types</em></MenuItem>
        <MenuItem value={ACCOUNT_TYPES.TRADING_ACCOUNT}><em>Trading Account</em></MenuItem>
        <MenuItem value={ACCOUNT_TYPES.REBATE_ACCOUNT}><em>Rebate Account</em></MenuItem>
      </SelectFilter>

    {isVisible("action") && (
      <TradeActionSelect
          data={actionList}
          onChange={(val) => setSelectedActions(val)}
          value={seletedActionList}
      />
    )}

    {isVisible("mt4Account") && (
    <Mt4Select
        data={mt4AccountList}
        onChange={(val) => setSelectedMt4Accounts(val)}
        value={seletedMt4AccountList}
    />
    )}

    {isVisible("country") && (
      <CountrySelect
          data={countryList}
          onChange={(val) => selectCountries(val)}
          value={selectedCountries}
        />
    )}

    {/* <pre>{JSON.stringify(tradeState, null, 2)}</pre> */}
    </>
  );
};

export default TradeFilter;
