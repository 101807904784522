import React from "react";
import SelectFilter from '../searchBar/SelectFilter';
import IconButton from '@mui/material/IconButton';
import SettingButton from '@mui/icons-material/ManageAccountsRounded';
import TreeFilter from "./TreeFilter";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 100,
    marginTop: 15,
    marginLeft: 15
  },
  adornedEnd: {
    backgroundColor: "#ccc",
    height: "2.4rem",
    maxHeight: "3rem",
  },
  icon: {
    color: "theme.palette.text.primary"
  }
}));

export default function UserLevelFilter({
    levelId,
    showAll,
    setLevelId,
    setShowAll,
    userAndLevelName,
    setUserAndLevelName,
}) {
    const classes = useStyles();
    const [userOpen, setUserOpen] = React.useState(false);
    const handleUserOpen = () => setUserOpen(true);
    const handleUserClose = () => setUserOpen(false);

    return (<div>
          <SelectFilter
            value={userAndLevelName}
            size="small"
            variant="outlined"
            label="User & Level"
            onChange={(e) => setUserAndLevelName(e.target.value)}
            onClick={handleUserOpen}
            placeholder='All Data'
            InputLabelProps={{
              shrink: true,
              readOnly: true
            }}
            InputProps={{
              readOnly: true,
              endAdornment:
              <IconButton onClick={handleUserOpen}><SettingButton className={classes.icon} /></IconButton>
            }}
          />
          <TreeFilter
          open={userOpen}
          handleClose={handleUserClose}
          setNodeId={setLevelId}
          nodeId={levelId}
          name={userAndLevelName}
          setName={setUserAndLevelName}
          showAll={showAll}
          setShowAll={setShowAll}
        ></TreeFilter>
        </div>)
}
