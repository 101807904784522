import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { pink } from "@mui/material/colors";
import useAPIError from "../common/hooks/useAPIError";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import theme from '../constants/theme';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiAlert-filledError": {
      backgroundColor: pink[500],
    },
  },
  alert: {
    minWidth: "220px",
    maxWidth: "350px",
  },
}));

export default function ErrorNotification() {
  const classes = useStyles();
  const { error, removeError } = useAPIError();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    removeError();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        data-testid="alert-snackbar"
      >
        <Alert
          severity="error"
          className={classes.alert}
          action={
            <IconButton
              data-testid="alert-close-button"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
