import React, { useState, useCallback, useEffect } from 'react';
import { apiGetRebateDetailSymbolData } from "../../resource/index.js";
import DataGrid from '../../components/DataGrid';

const columns = [
  { selector: 'symbol', name: 'Symbol', sortable: true },
  { selector: 'lots', name: 'Lots', sortable: true },
  { selector: 'notionalValue', name: 'Notional Value', format: row => row.notionalValue ? row.notionalValue : "N/A", sortable: true },
  { selector: 'rebate', name: 'Rebate', sortable: true }
];

const customStyles = {
  headCells: {
    style: {
      fontSize: '1rem',
      fontWeight: 'bold',
      backgroundColor: "#fafafa",
      paddingLeft: '100px'
    },
  },
  cells: {
    style: {
      paddingLeft: '100px',
    },
  },
};

const RebateDetailSymbol = ({
  detail,
  mt4Account
}) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    regulator,
    agentAccount,
    from,
    to,
    groupName,
    dataSource,
    tradeOnCredit } = detail;
  const fetchData = useCallback(() => {
    setLoading(true);
    setRows([])
    apiGetRebateDetailSymbolData({
      regulator,
      from,
      to,
      mt4Account,
      agentAccount,
      groupName,
      dataSource: dataSource || undefined,
      tradeOnCredit: tradeOnCredit || ''
    }).then((res) => {
      if (res.data.status === "success") {
        setRows(res.data.data.symbol)
      }
      setLoading(false); 
    })
      .catch((e) => { setLoading(false); })
  }, [regulator, from, to, mt4Account, agentAccount, groupName, dataSource, tradeOnCredit]);

  useEffect(() => {
    setRows([]);// TODO: add spinner
    if (regulator) {
      fetchData();
    }
  }, [regulator, from, to, mt4Account, agentAccount, groupName, dataSource, tradeOnCredit]);
  
  return (<DataGrid
      dense={true}
      columns={columns}
      data={rows}
      props={{ noHeader: true, dense: true, customStyles }}
      progressPending={loading}
    />)
}

export default RebateDetailSymbol;