import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import DateRangeFilter from '../../components/searchBar/DateRangeFilter';
import {
  DEFAULT_CCTRANSACTION_STATE,
} from "../../constants/constants";
import * as types from '../../constants/actionTypes';
import AddCircle from '@mui/icons-material/AddCircle';
import UserLevelFilter from "../../components/searchBar/UserLevelFilter";
import { makeStyles } from "@mui/styles";
import theme from '../../constants/theme';
import CCDropDownMenu from "./CCDropDownMenu";
import {
  apiGetCCTransactionChannelData,
  apiGetCCTransactionTransTypeData,
  apiGetCCTransactionStatusData,
  apiGetCCTransactionSdVerifyData
} from "../../resource/index.js";
import Storage from "../../common/lib/storage";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
    fontStyle: "italic",
  },
  selectControl: {
    width: 250,
  },
  selectedFilters: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const CCTransactionFilter = () => {
  const classes = useStyles();
  const { filters, levelId, showAll, userAndLevelName, selectedCreditCardChannel, selectedTransactionType, selectedCcStatus, selectedSourceDataVerifyStatus, ccTransactionState } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setFilters = (filters) => dispatch({ type: types.SET_FILTERS, filters });
  const setSelectedCcChannel = (val) => dispatch({ type: types.SET_SELECTED_CREDITCARD_CHANNEL, value: val });
  const setSelectedTransactionType = (val) => dispatch({ type: types.SET_SELECTED_CCTRANSACTION_TYPE, value: val });
  const setSelectedCcStatus = (val) => dispatch({ type: types.SET_SELECTED_CREDITCARD_STATUS, value: val });
  const setSelectedSourceDataVerify = (val) => dispatch({ type: types.SET_SELECTED_SOURCEDATAVERIFY_STATUS, value: val });
  const setCCTransactionState = (val) => dispatch({ type: types.SET_CCTRANSACTION_STATE, value: val });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });

  const [ccStatusObject, setCcStatusObject] = useState({});
  const [ccStatusMenuList, setCcStatusMenuList] = useState(null);
  const [pathname, setPathname] = useState(window.location.pathname);

  const filterList = [
    {
      label: "Date Range",
      value: "dateRange",
      keys: ["fromDate", "toDate"],
      required: true,
    },
    {
      label: "User & Level",
      value: "userAndLeve",
      keys: ["userAndLeve"],
      required: true,
    },
    {
      label: "Credit Card Channels",
      value: "creditCardChannel",
      keys: ["creditCardChannel"],
      show: true,
    },
    {
      label: "Transaction Type",
      value: "transactionType",
      keys: ["transactionType"],
      show: true,
    },
    {
      label: "Credit Card Status",
      value: "ccStatus",
      keys: ["ccStatus"],
      show: true,
    },
    {
      label: "Source Data Verified",
      value: "sourceDataVerified",
      keys: ["sourceDataVerified"],
      show: true,
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState(
    filterList.filter((r) => r.required || r.show).map((r) => r.value)
  );

  const toggleFilter = (val) => {
    setSelectedFilters(val);
    // reset filter value if it's not selected
    const filterKeys = filterList
      .filter((r) => val.includes(r.value))
      .flatMap((r) => r.keys);
    const newFilters = { ...ccTransactionState};
    Object.keys(ccTransactionState).forEach((r) => {
      if (!(filterKeys.includes(r) )) {
        newFilters[r] = DEFAULT_CCTRANSACTION_STATE[r];
        setCCTransactionState({ ...ccTransactionState, r: DEFAULT_CCTRANSACTION_STATE[r]});
      }
    });
    setCCTransactionState(newFilters);
    setCcStatusMenuList(null);
  };

  const isVisible = (id) => {
    return selectedFilters.includes(id);
  };

  const selectedFilterCount = selectedFilters.length;

  useEffect(() => {
    const {
      fromDate,
      toDate,
      selectedCreditCardChannel,
      selectedTransactionType,
      selectedCcStatus,
      selectedSourceDataVerifyStatus,
    } = ccTransactionState;

    setFilters({
      fromDate,
      toDate,
      selectedCreditCardChannel,
      selectedTransactionType,
      selectedCcStatus,
      selectedSourceDataVerifyStatus,
      levelId,
      showAll,
    });
  }, [ccTransactionState, selectedCreditCardChannel, selectedTransactionType, selectedCcStatus, selectedSourceDataVerifyStatus, levelId, showAll]);

  const setStatusMenu = (inputObject, selectedValue) => {
    if (selectedValue === undefined || inputObject[selectedValue] === undefined) {
      setCcStatusMenuList("");
    } else {
      setCcStatusMenuList(inputObject[selectedValue]);
    }
  };

  const fetchStatusData = (selectedValue) => {
    const regulator = Storage.getRegulator() ?? "";
    apiGetCCTransactionStatusData(regulator)
      .then((res) => {
        if (res.data.status === "success") {
          setCcStatusObject(res.data.data.creditCardStatus);
          setStatusMenu(res.data.data.creditCardStatus, selectedValue)
        }
      })
      .catch((e) => {
        setCcStatusObject({});
      });
  };

  // Store the page path
  React.useEffect(() => {
    const handlePathnameChange = () => {
      setPathname(window.location.pathname);
    };
    handlePathnameChange();
    window.addEventListener('popstate', handlePathnameChange);
    return () => {
      window.removeEventListener('popstate', handlePathnameChange);
    };
  }, []);

  React.useEffect(() => {
    // Reset to default value when page path changed
    setCCTransactionState(DEFAULT_CCTRANSACTION_STATE);
  }, [pathname]);

  return (
    <>
      <div className={classes.formControl}>
        <InputLabel id="filter-mutiple-checkbox-label">
          <AddCircle color="primary" />
        </InputLabel>
        <Select
          className={classes.selectControl}
          labelId="filter-mutiple-checkbox-label"
          id="filter-mutiple-checkbox"
          multiple
          value={selectedFilters}
          onChange={(e) => toggleFilter(e.target.value)}
          input={<Input />}
          renderValue={(selected) => `${selectedFilterCount} filters selected`}
        >
          {filterList.map((option) => (
            <MenuItem
              key={option.value}
              disabled={option.required}
              value={option.value}
            >
              <Checkbox
                color="primary"
                checked={selectedFilters.includes(option.value)}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <DateRangeFilter
        selectedFromDate={ccTransactionState.fromDate}
        setSelectedFromDate={(val) => setCCTransactionState({ ...ccTransactionState, fromDate: val })}
        selectedToDate={ccTransactionState.toDate}
        setSelectedToDate={(val) => setCCTransactionState({ ...ccTransactionState, toDate: val })}
      />

      {isVisible("userAndLeve") && (
          <UserLevelFilter
            levelId={levelId}
            setLevelId={setLevelId}
            showAll={showAll}
            setShowAll={setShowAll}
            userAndLevelName={userAndLevelName}
            setUserAndLevelName={setUserAndLevelName}
          />
        )}

      {isVisible("creditCardChannel") && (
        <CCDropDownMenu
          label="Credit Card Channels"
          value={ccTransactionState.selectedCreditCardChannel}
          defaultValue="All Channels"
          apiFunction={apiGetCCTransactionChannelData}
          dataKey="creditCardChannel"
          onChange={(val) => {
            setCCTransactionState({ ...ccTransactionState, selectedCreditCardChannel: val})
            setSelectedCcChannel(val)
          }}
        />
      )}

      {isVisible("transactionType") && (
        <CCDropDownMenu
          label="Transaction Type"
          value={ccTransactionState.selectedTransactionType}
          defaultValue="All Transaction Types"
          apiFunction={apiGetCCTransactionTransTypeData}
          dataKey="creditCardTransType"
          onChange={(val) => {
            setCCTransactionState({ ...ccTransactionState, selectedTransactionType: val, selectedCcStatus: DEFAULT_CCTRANSACTION_STATE[selectedCcStatus]})
            setSelectedTransactionType(val)
            if (Object.keys(ccStatusObject).length === 0) {
              fetchStatusData(val);
            } else {
              setStatusMenu(ccStatusObject, val);
            }
          }}
        />
      )}

      {isVisible("ccStatus") && (
        <CCDropDownMenu
          label="Credit Card Status"
          value={ccTransactionState.selectedCcStatus}
          defaultValue="All Status"
          apiFunction={null}
          dataKey="creditCardStatus"
          onChange={(val) => {
            setCCTransactionState({ ...ccTransactionState, selectedCcStatus: val})
            setSelectedCcStatus(val)
          }}
          inputMenuList={ccStatusMenuList}
        />
      )}

      {isVisible("sourceDataVerified") && (
        <CCDropDownMenu
          label="Source Data Verified"
          value={ccTransactionState.selectedSourceDataVerifyStatus}
          defaultValue="All Verify Status"
          apiFunction={apiGetCCTransactionSdVerifyData}
          dataKey="creditCardSdVerify"
          onChange={(val) => {
            setCCTransactionState({ ...ccTransactionState, selectedSourceDataVerifyStatus: val})
            setSelectedSourceDataVerify(val)
          }}
        />
      )}

      {/* <pre>{JSON.stringify(ccTransactionState, null, 2)}</pre> */}
    </>
  );
};

export default CCTransactionFilter;
