import { instance } from "../axios/WithAxios.js";

const baseUrl = "/api/";

// dropdown
export const apiGetCountry = (regulator) =>
  instance.get(`${baseUrl}country`, { params: { regulator: regulator } });
export const apiGetCurrency = (regulator) =>
  instance.get(`${baseUrl}currency`, { params: { regulator: regulator } });
export const apiGetDataSource = (regulator) =>
  instance.get(`${baseUrl}datasource`, { params: { regulator: regulator } });
export const apiGetPayment = (regulator, dataType) =>
  instance.get(`${baseUrl}payment`, { params: { regulator, dataType } });
export const apiGetPaymentStatus = (regulator, dataType) =>
  instance.get(`${baseUrl}payment-status`, { params: { regulator, dataType} });
export const apiGetPaymentChannel = (regulator, dataType, paymentMethod) =>
  instance.get(`${baseUrl}payment-channel`, { params: { regulator, dataType, paymentMethod} });

export const apiGetCurrencyPrice = (regulator, currency) =>
  instance.get(`${baseUrl}currency-price`, { params: { regulator, currency} });


// download
export const apiGetDownloadData = (data) =>
  instance.post(`${baseUrl}rebate/download`, data);
export const apiGetDepositDownloadData = (data) =>
  instance.post(`${baseUrl}deposit/download`, data);
export const apiGetTradeDownloadData = (data) =>
  instance.post(`${baseUrl}trade/download`, data);
export const apiDownloadFile = (file) =>
  instance.get(`${baseUrl}download/${file}`);
export const apiGetDepositDifferenceDownloadData = (data) =>
  instance.post(`${baseUrl}deposit-diff/download`, data);

// rebate report
export const apiGetRebateData = (data) =>
  instance.post(`${baseUrl}rebate`, data);

// rebate summary
export const apiGetRebateSummaryData = (data) =>
  instance.post(`${baseUrl}rebate/summary`, data);

// rebate detail
export const apiGetRebateDetailData = (data) =>
  instance.post(`${baseUrl}rebate/detail`, data);

// rebate detail symbol list
export const apiGetRebateDetailSymbolData = (data) =>
  instance.post(`${baseUrl}rebate/detail-symbol`, data);

// user
export const apiGetUserName = () =>
  instance.get(`${baseUrl}user`);
  
// deposit report
export const apiGetDepositData = (data) =>
  instance.post(`${baseUrl}deposit`, data);

// deposit summary
export const apiGetDepositSummary = (data) =>
  instance.post(`${baseUrl}deposit/summary`, data);

// deposit report time difference
export const apiGetDepositDifferenceData = (data) =>
  instance.post(`${baseUrl}deposit-diff`, data);
export const apiGetDepositDifferenceSummary = (data) =>
  instance.post(`${baseUrl}deposit-diff/summary`, data);

// daily deposit-withdrawal report
export const apiGetDailyDepositData = (data) =>
  instance.post(`${baseUrl}deposit/daily_report`, data);
export const apiDailyDepositFileDownload = (file) =>
  instance.get(`${baseUrl}download/deposit/daily_report/${file}`,
               {responseType: 'blob'});

// deposit mt4 account
export const apiGetMt4Account = () =>
  instance.get(`${baseUrl}account-type`);

export const apiGetTradeAction = () =>
  instance.get(`${baseUrl}tradeaction`);

// campaign
export const apiGetCampaign = (regulator) =>
  instance.get(`${baseUrl}campaign`, { params: { regulator: regulator } });

// channel upload
export const apiChannelUpload = (data) =>
  instance.post(`${baseUrl}channel/upload`, data);

export const apiGetChannelList = () =>
  instance.get(`${baseUrl}channel`);

// user level tree
export const apiGetUserLevelTree = (data) =>
  instance.post(`${baseUrl}user-level-tree`, data);

//FTD report
export const apiGetFTDeposit = (data) =>
  instance.post(`${baseUrl}deposit/ftd`, data);
//FTD report
export const apiFTDepositDownload = (data) =>
  instance.post(`${baseUrl}deposit/ftddownload`, data);

  // trade report
export const apiGetTradeData = (data) =>
instance.post(`${baseUrl}trade`, data);

// clear cache
export const apiGetClearCache = (regulator) =>
  instance.get(`${baseUrl}clear-cache`, { params: { regulator: regulator } });

// trade report v2
export const apiGetTradeV2Data = (data) =>
  instance.post(`${baseUrl}trade-v2`, data);
export const apiGetTradeDownloadV2Data = (data) =>
  instance.post(`${baseUrl}trade-v2/download`, data);

// Credit Card Transaction
export const apiGetCCTransactionData = (data) =>
  instance.post(`${baseUrl}cc-transaction`, data);
export const apiGetCCTransactionDownloadData = (data) =>
  instance.post(`${baseUrl}cc-transaction/download`, data);
export const apiGetCCTransactionChannelData = (regulator) =>
  instance.get(`${baseUrl}cc-transaction/channel`, { params: { regulator } });
export const apiGetCCTransactionTransTypeData = (regulator) =>
  instance.get(`${baseUrl}cc-transaction/trans-type`, { params: { regulator } });
export const apiGetCCTransactionStatusData = (regulator) =>
  instance.get(`${baseUrl}cc-transaction/cc-status`, { params: { regulator } });
export const apiGetCCTransactionSdVerifyData = (regulator) =>
  instance.get(`${baseUrl}cc-transaction/sd-verify`, { params: { regulator } });

// Credit Card Withdraw Detail Report
export const apiGetCCDetailData = (data) =>
  instance.post(`${baseUrl}cc-detail`, data);
export const apiGetCCDetailDownloadData = (data) =>
  instance.post(`${baseUrl}cc-detail/download`, data);
export const apiGetCCDetailChannelData = (regulator) =>
  instance.get(`${baseUrl}cc-detail/channel`, { params: { regulator } });
