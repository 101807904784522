import moment from "moment";

export const ROUTES = {
  REBATE_REPORT: "/rebateReport",
  TRADE_REPORT: "/tradeReport",
  TRADE_REPORT_V2: "/tradeReportV2",
  DEPOSIT_REPORT: "/depositReport",
  DEPOSIT_DAILY_REPORT: "/depositDailyReport",
  FTD_DEPOSIT: "/ftDeposit",
  CAMPAIGN_REPORT: "/campaignReport",
  CHANNEL_DATA: "/channelData",
  CREDIT_CARD_TRANSACTION_REPORT: "/ccTransactionReport",
  CREDIT_CARD_WITHDRAWAL_DETAIL_REPORT: "/ccWithdrawDetailReport",
};

export const ACCOUNT_TYPES = {
  TRADING_ACCOUNT: 1,
  REBATE_ACCOUNT: 3,
};

export const FUND_TYPES = {
  DEPOSIT: 1,
  WITHDRAW: 2,
  CASH_ADJUSTMENT: 3,
};

export const DEPOSIT_PAYMENT_STATUS = {
  SUCCEED: 5,
};

export const WITHDRAW_PAYMENT_STATUS = {
  COMPLETED: 7,
};

export const LOTS_SUFFIX = "_lots";

export const DEFAULT_DEPOSITATE_STATE = {
  reset: false,
  fromDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
  toDate: moment().clone().format("YYYY-MM-DD"),
  accountType: ACCOUNT_TYPES["TRADING_ACCOUNT"],
  userAndLeve: "",
  mt4Account: true,
  deposit: true,
  withdraw: false,
  timeDifference: false,
  cashAdjustment: false,
  depositDataType: FUND_TYPES["DEPOSIT"],
  depositPaymentStatus: DEPOSIT_PAYMENT_STATUS["SUCCEED"],
  depositPaymentMethod: "",
  depositPaymentChannel: "",
  cashAdjustmentDataType: FUND_TYPES["CASH_ADJUSTMENT"],
  withdrawDataType: FUND_TYPES["WITHDRAW"],
  withdrawPaymentStatus: WITHDRAW_PAYMENT_STATUS["COMPLETED"],
  withdrawPaymentMethod: "",
  withdrawPaymentChannel: "",
};

export const DEFAULT_TRADE_STATE = {
  fromDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
  toDate: moment().clone().format("YYYY-MM-DD"),
  accountType: ACCOUNT_TYPES["TRADING_ACCOUNT"],
  userAndLeve: "",
  mt4Account: true,
  action: "",
  server: [],
};

export const DEFAULT_REBATE_STATE = {
  fromDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
  toDate: moment().clone().format("YYYY-MM-DD"),
  dataSource: "",
  tradeOnCredit: "0",
  userAndLeve: "",
};

export const DEFAULT_FTD_STATE = {
  fromDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
  toDate: moment().clone().format("YYYY-MM-DD"),
  paymentMethod: "",
  paymentChannel: "",
  userAndLeve: "",
};

export const DEFAULT_CCTRANSACTION_STATE = {
  fromDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
  toDate: moment().clone().format("YYYY-MM-DD"),
  userAndLeve: "",
  selectedCreditCardChannel: "",
  selectedTransactionType: "",
  selectedCcStatus: "",
  selectedSourceDataVerifyStatus: "",
};

export const DEFAULT_CCWITHDRAWDETAIL_STATE = {
  reset: false,
  fromDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
  toDate: moment().clone().format("YYYY-MM-DD"),
  accountType: ACCOUNT_TYPES["TRADING_ACCOUNT"],
  userAndLeve: "",
  mt4Account: true,
  seletedCCWithdrawDetailStatus: "",
  seletedCCWithdrawDetailChannelList: [],
};
