import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import AdvancedTable from "../../components/AdvancedTable";
import {
  StringComboFilter,
  NumberComboFilter,
  SelectMultipleColumnsFilter,
} from "../../components/filterTypes/filterTypes";
import {
  apiGetCurrency,
  apiDownloadFile,
  apiGetCCTransactionDownloadData,
  apiGetCCTransactionData,
} from "../../resource/index.js";
import Storage from "../../common/lib/storage";
import DateRange from "../../common/lib/dateRange";
import * as types from '../../constants/actionTypes';
import {
  DEFAULT_CCTRANSACTION_STATE,
} from '../../constants/constants';
import useAPIError from "../../common/hooks/useAPIError";
import theme from '../../constants/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: (dynamicWidth) => dynamicWidth - theme.spacing(39).replace('px', ''),
  },
  topDiv: {
    display: 'flex',
    height: "500px",
    position: 'relative',
    width: (dynamicWidth) => dynamicWidth - theme.spacing(40).replace('px', ''),
  },
}));

export default function CCTransactionReport() {
  const { ccTransactionState } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setCCTransactionState = (val) => dispatch({ type: types.SET_CCTRANSACTION_STATE, value: val });
  const setSelectedCcChannel = (val) => dispatch({ type: types.SET_SELECTED_CREDITCARD_CHANNEL, value: val });
  const setSelectedTransactionType = (val) => dispatch({ type: types.SET_SELECTED_CCTRANSACTION_TYPE, value: val });
  const setSelectedCcStatus = (val) => dispatch({ type: types.SET_SELECTED_CREDITCARD_STATUS, value: val });
  const setSelectedSourceDataVerify = (val) => dispatch({ type: types.SET_SELECTED_SOURCEDATAVERIFY_STATUS, value: val });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });
  const { addError } = useAPIError();
  const [currency, setCurrency] = React.useState([]);
  // start table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [noData, setNoData] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [selectedSort, setSelectedSort] = React.useState({});
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [summary, setSummary] = React.useState([]);
  const regulator = Storage.getRegulator() ?? "";
  const { filters } = useSelector(state => state.report);
  const [dynamicColumns, setDynamicColumns] = React.useState([]);

  React.useEffect(() => {
    const displayClickId = (Storage.getDisplayClickID() === '1');
    if (displayClickId === true) {
      setDynamicColumns([]);
    }
  }, []);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "User Id",
          accessor: "user_id",
          id: "userId",
          disableSortBy: true,
          disableFilters: false,
          Filter: NumberComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Trading Account",
          accessor: "mt_account",
          id: "mtAccount",
          disableSortBy: true,
          disableFilters: false,
          Filter: NumberComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Card Holder Name",
          accessor: "card_holder_name",
          id: "cardHolderName",
          disableSortBy: true,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "First 6 on Card",
          accessor: "card_begin_six_digits",
          disableSortBy: true,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Last 4 on Card",
          accessor: "card_last_four_digits",
          disableSortBy: true,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Expiry Month",
          accessor: "expiry_month",
          disableSortBy: true,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Expiry Year",
          accessor: "expiry_year",
          disableSortBy: true,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Channel",
          accessor: "channel",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "PSP Name",
          accessor: "psp_name",
          id: "pspName",
          disableSortBy: true,
          disableFilters: false,
          alwaysVisible: true,
          Filter: StringComboFilter,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Order Number",
          accessor: "order_number",
          id: "orderNumber",
          disableSortBy: true,
          disableFilters: false,
          alwaysVisible: true,
          Filter: StringComboFilter,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Currency",
          accessor: "currency",
          disableSortBy: false,
          Filter: ({ column }) =>
            SelectMultipleColumnsFilter({ column }, { dropdown: currency }),
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Amount",
          accessor: "amount",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Transaction Type",
          accessor: "transaction_type",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Create Time",
          accessor: "create_time",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Update Time",
          accessor: "update_time",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Transaction Time",
          accessor: "transaction_time",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Expiry Status",
          accessor: "expiry_time",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Process Notes",
          accessor: "process_notes",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Operator",
          accessor: "operator",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Source Data Verified",
          accessor: "is_verified",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        ...dynamicColumns,
      ], [currency, dynamicColumns]);

  const downloadFile = (file) => {
    apiDownloadFile(file)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([
            new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
            "",
            res.data],
            { type: "text/plain;charset=utf-8" }
            ));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
  };

  const getDownloadData = () => {
    setLoading(true);
    apiGetCCTransactionDownloadData({
      regulator: regulator,
      sort: selectedSort,
      filter: selectedFilter ? selectedFilter : {},
      from: filters.fromDate || "",
      to: filters.toDate || "",

      countryCodes: filters.countryCodes || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
      ccChannel: ccTransactionState.selectedCreditCardChannel ? [ccTransactionState.selectedCreditCardChannel] : [] || undefined,
      ccTransType: ccTransactionState.selectedTransactionType ? [ccTransactionState.selectedTransactionType] : [] || undefined,
      ccStatus: ccTransactionState.selectedCcStatus ? [[ccTransactionState.selectedTransactionType, ccTransactionState.selectedCcStatus]] : [] || undefined,
      ccSdVerify: ccTransactionState.selectedSourceDataVerifyStatus ? [ccTransactionState.selectedSourceDataVerifyStatus] : [] || undefined,
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          downloadFile(res.data.data.file);
        }
      })
      .catch(() => {
        setLoading(false);
	  })
  };

  const getData = React.useCallback(async () => {
    try {
      const currency_res = (await apiGetCurrency(regulator)).data;
      setCurrency(currency_res.data.currency);
    } catch (err) {
      console.log(err);
      setCurrency([]);
    }
  }, [regulator]);

  React.useEffect(() => {
    if (regulator) {
        getData();
    }
  }, [regulator]);

  const fetchData = ({ pageSize, pageIndex, sortParams, filterParams }) => {
    if (DateRange.FindRangeError(filters.fromDate, filters.toDate)) {
      addError(DateRange.FindRangeError(filters.fromDate, filters.toDate));
      return;
    }
    const filterOptions = {
      user_id: filterParams.userId || undefined,
      mt_account: filterParams.mtAccount || undefined,
      card_holder_name: filterParams.cardHolderName || undefined,
      psp_name: filterParams.pspName || undefined,
      order_number: filterParams.orderNumber || undefined,
      currency: filterParams.currency || undefined,
    }
    setSelectedSort(sortParams);
    setSelectedFilter(filterOptions);
    setLoading(true);
    setNoData(false);
    apiGetCCTransactionData({
      regulator: regulator,
      pageNo: pageIndex + 1,
      pageSize: pageSize,
      sort: sortParams,
      filter: filterOptions ? filterOptions : {},
      from: filters.fromDate,
      to: filters.toDate,

      countryCodes: filters.countryCodes || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
      ccChannel: ccTransactionState.selectedCreditCardChannel ? [ccTransactionState.selectedCreditCardChannel] : [] || undefined,
      ccTransType: ccTransactionState.selectedTransactionType ? [ccTransactionState.selectedTransactionType] : [] || undefined,
      ccStatus: ccTransactionState.selectedCcStatus ? [[ccTransactionState.selectedTransactionType, ccTransactionState.selectedCcStatus]] : [] || undefined,
      ccSdVerify: ccTransactionState.selectedSourceDataVerifyStatus ? [ccTransactionState.selectedSourceDataVerifyStatus] : [] || undefined,
    })
      .then((res) => {
        if (res.data.status === "success") {
          setData(res.data.data.cc_transaction);
          setCount(res.data.data.total);
          setPageCount(Math.ceil(res.data.data.total / pageSize));
          // setSummary(res.data.data.summary);
        }else{
          setNoData(true);
          setData([]);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setNoData(true);
        setData([]);
        setSummary([]);
      })

  }

  const [dynamicWidth, setDynamicWidth] = React.useState(window.innerWidth);
  const classes = useStyles(dynamicWidth);

  function handleResize() {
    setDynamicWidth(window.innerWidth)
  }
  window.addEventListener('resize', handleResize)

  const resetFilter = () => {
    setSelectedCcChannel("");
    setSelectedTransactionType("");
    setSelectedCcStatus("");
    setSelectedSourceDataVerify("");
    setUserAndLevelName("");
    setLevelId("");
    setShowAll(true);
    setCCTransactionState(DEFAULT_CCTRANSACTION_STATE);
  }

  return (
    <div className={classes.root}>
      <AdvancedTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        noData={noData}
        count={count}
        pageCount={pageCount}
        getDownloadData={getDownloadData}
        resetFilter={resetFilter}
      />
    </div>
  );
}
